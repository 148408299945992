.input_dropdown_container {
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  height: fit-content;
  max-height: 320px;
  overflow-y: auto;
  background-color: $white;
  z-index: $z_index_second_floor;
  border: $border_gray_100;
  box-shadow: $box_shadow_lg;
  border-radius: $primary_border_radius;
}

.input_dropdown_item {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 12px 0 14px;
  background-color: $white;
  cursor: pointer;
  &:hover {
    background-color: $gray-50;
  }
}
