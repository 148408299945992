.loungeStepTwoDate {
  margin-top: 16px;
  .subtitle {
    font-size: 18px;
    line-height: 27px;
    @include Montserrat(600);
    color: $gray-800;
    margin-bottom: 16px;
  }

  p {
    font-size: 14px;
    line-height: 21px;
    @include Montserrat(500);
    color: $gray-800;
    margin: 0;
  }

  .loungeSelectBox {
    background-color: $bg-gray-1;
    color: $gray-300;
  }

  .loungeCalendar {

    @include phone {
      flex-direction: column;
      align-items: center;
    }
    .react-calendar {
      width: 430px;
      border: none;

      @include minDesktop {
        min-width: 369px;
        max-width: 369px;
      }

      @include phone {
        min-width: 300px;
        max-width: 300px;
      }

      .react-calendar__navigation__arrow {
        order: 1;
      }

      .react-calendar__navigation__label {
        text-align: start;
        @include Montserrat(500);
      }

      abbr {
        text-decoration: none;
        cursor: default;
      }

      abbr[title] {
        text-decoration: none;
        border-bottom: none;
        @include Montserrat(400);
      }

      .react-calendar__month-view__weekdays__weekday {
        text-decoration: none;
      }

      .react-calendar__tile {
        height: auto;
        border-radius: 50%;
        @media (min-width: 1600px) {
          height: 60px;
        }

        @include minDesktop {
          height: 49px;
        }

        @include phone {
          height: 42px;
        }
      }

      .react-calendar__tile {
        &--now {
          background: transparent;
        }

        &--active {
          background: $dark-blue;
          color: $white !important;
        }
      }

      .react-calendar__month-view__days__day--weekend {
        color: $black;
      }

      .react-calendar__month-view__days__day--neighboringMonth {
        visibility: hidden;
      }
    }

    .selectTime {
      .showDay {
        height: 44px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .selectedDate {
          @include Montserrat(500);
          font-size: 16px;
        }
      }

      .timeSelectBox {
        margin-bottom: 20px;
        .showTime {
          width: 100%;
          border: 1px solid $light-blue;
          color: $dark-blue;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 0;
          border-radius: 5px;
          @include Montserrat(700);
        }
      }
    }
  }
}

.calendly-inline-widget {
  height: 500px !important;
  width: 400px !important;

  @include phone {
    width: 320px !important;
  }
}
