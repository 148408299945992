@import '../../scss/variables';

//---------------------------------------------
// 4. Viewport sizes
//---------------------------------------------

@mixin smallPhone {
  @media (max-width: 420px) {
    @content;
  }
}

@mixin phone {
  @media (max-width: 767.8px) {
    @content;
  }
}

@mixin ipad {
  @media (max-width: 1023.8px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1024px) {
    @content;
  }
}

.website_cover_image_holder {
  height: 900px;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  position: relative;
}

.highlights-sec {
  transition: 0.4s;
}

.make_parallax_highlights {
  transform: translateY(0) !important;
}

.wrapper {
  position: relative;
  width: 100%;
  & > div {
    transition: all 1s;
  }

  & > section {
    .text-box {
      transition: all 1s;
    }
  }
}

.mobile_address_row {
  display: flex;
  flex-direction: column;
  column-gap: 5px;
}

.cover_video_play_btn {
  position: absolute;
  left: calc(50% - 45px);
  top: calc(50% - 45px);
  z-index: $z_index_third_floor;
}

.close_container {
  position: absolute;
  width: auto;
  padding: 5px;
  border: 2px solid $white;
  color: $white;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  left: calc(50% - 45px);
  top: calc(50% - 45px);
}

.wrapper {
  background-color: $white;
  position: relative;

  @include ipad {
    .gallery-inner {
      ul {
        li {
          padding: 2.5px;
          .img-fluid {
            height: 100%;
          }
        }
      }
    }
  }

  .title {
    h2 {
      font-family: Fira Sans Condensed !important;
    }
  }

  .highlights-inner_icon {
    max-height: 45px;
    max-width: 45px;
  }

  .modal_section {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: $z_index_last_floor;

    .modal-dialog-centered_section,
    .modal-content_section,
    .modal-body_section,
    .fade_section,
    .video-modal_section {
      height: 100%;
    }

    .modal-body_section {
      position: relative;
    }
    .close-btn_section {
      position: absolute;
      cursor: pointer;
      top: 10px;
      @include phone {
        top: 22%;
      }
    }
  }

  .close-btn_section {
    cursor: pointer;
    z-index: 9999999999;
    top: 10px;
  }

  .play-btn {
    cursor: pointer;
  }

  .modal-body_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .close-btn_section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background-color: $white;
    margin-bottom: 10px;
    margin-top: 10px;
    z-index: 9999999999;
    top: 10px;
  }

  .modal-dialog_section {
    max-width: 796px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .image-holder {
    width: 100vw;

    @media (min-width: 768px) {
      .img-fluid {
        height: 900px;
        object-fit: cover;
      }
    }
  }

  .agent_image_pw_section {
    border-radius: 50%;
  }

  .property_details_photo {
    border-radius: 10px;
    height: 100% !important;
    min-width: 50%;
    max-width: 100%;
    object-fit: cover !important;
  }

  .property_website_header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
  }

  .open-house-inner-description {
    width: 50%;

    @include ipad {
      width: 90%;
      text-align: center;
    }
  }

  .open-house-sec {
    display: flex;
    @include ipad {
      flex-wrap: wrap;
    }
    @include desktop {
      padding-left: 125px;
      padding-right: 85px;
    }
    & > div {
      min-width: 320px;
    }
  }

  .housedater_parts_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .open-house-inner {
      margin-left: 0;
    }
    @include ipad {
      align-items: center;
      .open-house-inner {
        display: flex;
        justify-content: center;
        margin: 0 auto;
      }
    }
  }

  .vide-iframe {
    max-width: calc(177.778vh);
    height: calc(56.25vw);
  }

  #video {
    margin-top: -3px;
  }

  .website_video_sec {
    padding-top: 60px;
  }

  .margin_bottom {
    margin-bottom: 30px;
  }
  @media (min-width: 993px) {
    .row {
      margin: 0 62px !important;
    }
  }
}

.navbar_close_icon {
  z-index: 999;
}

.close_header {
  transition: 0.5s;
  background: transparent;
  box-shadow: unset;
  border: none;
  padding: 0 !important;

  .nav-link {
    display: none;
  }
  * {
    border: none;
    box-shadow: unset;
    color: transparent;
    background: transparent;
  }
}

.matterport-sec {
  .container {
    max-width: 100% !important;
    padding: 0 !important;
    .col-12 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.matterport_iframe {
  width: 80vw;
  height: 90vh;
  border-radius: 8px;
}

.video_iframe {
  width: 100vw;
  height: 84.7vh;
  border-radius: 8px;
}

.mobile_video_iframe {
  width: 100%;
  height: 80vw;
  min-height: 192px;
  max-height: 300px;
}

.highlights-ul {
  @include ipad {
    .highlights_li {
      width: auto !important;
      column-gap: 40px;
      row-gap: 25.5px;
      @include phone {
        width: 30% !important;
      }
    }
  }
}

.detail_highlights_ul {
  @include phone {
    flex-direction: column;
  }
}

.matterport_inner {
  height: 714px;
  overflow: hidden;
  padding-top: 56.25%;
  iframe {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }
  @include phone {
    height: 192px;
  }
}

.matterport_absolute_div {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  background-color: transparent;
}

.matterport_in_page {
  height: 100%;
}

.matterport_play_btn {
  z-index: 9;
  top: 47% !important;
  .img-fluid {
    height: 120px !important;
    width: 125px !important;
  }
  @include phone {
    .img-fluid {
      height: 85px !important;
      width: 85px !important;
    }
    top: 50% !important;
  }
}

.powered_by_matterport {
  width: 100%;
  position: absolute;
  bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > p {
    color: $white;
    font-family: Nunito Sans;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }

  > img {
    width: 230px;
    height: 50px;
  }

  @include ipad {
    > p {
      font-size: 8px;
      line-height: 11px;
    }
    > img {
      width: 92.5px;
      height: 20px;
    }
  }
}

.details_section_part {
  @include ipad {
    padding: 50px 16px 0;
    .plan_header_item {
      padding: 27.5px !important;
    }
  }
}

.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: $primary_border_radius;
  &:hover {
    .gallery_cover_div {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      top: 0;
      left: 0;
      transition: 1s;
    }
    .gallery_img {
      transition: 1s;
      transform: scale(1.3);
    }
  }
}

.content_wrapper {
  background: $white;
  padding-top: 60px;
}

.agent_info_address {
  max-width: 400px;
  & > div {
    flex-wrap: wrap;
    display: flex;
  }
}

.banner_video_holder {
  height: 950px;
  position: relative;
}

.parallax_video_item {
  z-index: -1;
  position: fixed;
  object-fit: cover;
}

@include ipad {
  .disabled_touch {
    touch-action: none;
  }
}

@include phone {
  .disabled_touch {
    touch-action: none;
  }
  .mobile_address_icon {
    margin-top: -7px;
  }
  .matterport_iframe {
    width: 100vw;
    height: 100vh;
  }
}
