.property_logo_container {
  display: flex;
  flex-direction: row;
  column-gap: 20px;

  @include ipad {
    flex-direction: column;
    column-gap: normal;
    row-gap: 3px;
  }

  .property_logo {
    width: 300px;

    @media (max-width: 1100px) {
      width: 230px;
      height: auto;
    }    

    @include ipad {
      width: 166px;
    }
  }

  .logo_millennium {
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: $primary;
    font-family: Montserrat !important;

    @media (max-width: 1100px) {
      font-size: 10px;
    } 

    @include ipad {
      font-size: 13px;
    }
  }

  .logo_text_container {
    display: block;

    @include ipad {
      display: none;
    }
    .logo_brokerage {
      margin: 0;
      padding: 0;
      font-size: 9px;
      color: $primary;
      font-family: Montserrat !important;

      @media (max-width: 1100px) {
        font-size: 8px;
      } 

      @include ipad {
        display: none;
      }
    }
  }

  .mobile_logo_text_container {
    display: none;

    @include ipad {
      display: block;
    }
  }
}

.home_property_logo_container {

  .home_property_logo {
    width: 200px;

    @include ipad {
      width: 166px;
    }
  }

  .downloadLogo {
    width: 250px;

    @include ipad {
      width: 166px;
    }
  }

  .logo_text_container {
    .logo_millennium {
      margin: 0;
      padding: 0;
      font-size: 13px;
      color: $primary;
      font-family: Montserrat !important;
    }
  }
}

.hide_custom_logo {
  display: flex;
  @include ipad {
    display: none;
  }
}

.show_custom_logo {
  display: none; 

  @include ipad {
    display: block;
  }
}