@import '../../mixins.scss';

.lounge_download_component {
  padding-top: 34px;

  @include ipad {
    padding-top: 20px;
  }

  .package_title {
    font-family: Nunito Sans;
    font-weight: 700;
    font-size: 25px;
    line-height: 34px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $black;

    @include phone {
      font-size: 20px;
    }
  }

  .about_package {
    display: flex;
    justify-content: space-between;
    max-width: 150px;
  }

  .quantity_field,
  .size_filed {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $light-black;
  }
  .download_content {
    display: flex;
    align-items: center;
    position: relative;
    padding-bottom: 60px;
    margin-top: 25px;

    &::after {
      content: '';
      width: 100%;
      max-width: 1440px;
      height: 2px;
      background-color: $light-gray;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    @include ipad {
      padding-bottom: 30px;
    }

    @include phone {
      flex-direction: column;
    }

    .loungeVideo {
      width: 153px;
      height: 450px;
      max-height: 310px;
      position: relative;

      @include ipad {
        width: 153px;
        height: 257px;
      }
  
      @include phone {
        margin: auto;
      }
  
      .posterImage {
        height: 100%;
        width: 153px;
        object-fit: cover;
      }

      .play_button {
        position: absolute;
        width: 31px;
        height: 31px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .content_text_section {
      width: 50%;
      margin-left: 106px;

      @include ipad {
        margin-left: 30px;
        width: 100%;
      }

      @include phone {
        margin: 20px 15px 0;

      }

      .size_field_text {
        color: $black-opacity-4;
      }

      .download_lounge_subtitle {
        font-family: Nunito Sans;
        font-weight: 700;
        font-size: 22px;
        line-height: 30px;
        margin: 8px 0;
      }

      .download_lounge_description {
        font-family: Nunito Sans;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $black-opacity-7;
        margin-bottom: 8px;
        width: 75%;

        @include phone {
          width: 100%;
        }
      }
    }

    .download_learn_more {
      font-family: Nunito Sans;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $black;
    }

    .lounge_download_btn {
      margin-top: 38px;
      width: 100%;
      display: flex;
      justify-content: end;

      @include phone {
        margin-top: 20px;
      }
    }
  }
}