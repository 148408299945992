.download_alert_container {
  position: fixed;
  bottom: 31px;
  right: 100px;
  z-index: 10;

  .download_alert_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 0;
    background: #BEAF87;
    border-radius: 4px;
    cursor: pointer;
    height: 30px;

    .download_alert_bell {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 13px;
      margin: 0 13.33px 0 12.33px;
      height: 20px;
      width: 20px;
    }

    .download_alert_message {
      font-family: NunitoSans-Regular, sans-serif;
      font-style: normal;
      font-size: 13px;
      line-height: 15px;
      color: #ffffff;
      margin-right: 9px;
      display: block;

      &.download_alert_message_hide {
        display: none;
      }
    }
  }
}
