.photo_loading_wrapper {
  .photo_loading_outer {
    position: relative;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: #BDBDBD;
    padding: 3px;

    .photo_loading_inner {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #ffffff;

      svg {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
