@import '../../mixins.scss';

.loading-progress-bar {
  width: 48.1rem;
  height: 8px;
  border-radius: 4px;
  background-color: #dadada;

  @include ipad {
    width: 290px;
    height: 4px;
  }

  .loader-show-progress {
    background: $load-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 0;
    transition: 2.5s ease 0.3s;
    animation-delay: 6s;
    border-radius: 4px;
  }
}
