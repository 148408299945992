.item_container {
  width: 349px;
  display: flex;
  flex-direction: column;
  background-color: $main_gold_bg;
  padding: 24px 16px;
  border-radius: $primary_border_radius;
  gap: 8px;
  height: fit-content;
  min-height: 264px;
}

.header_container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.intro_item_title {
  margin: 0 38px 0 16px;
  align-self: center;

  font-size: $font16;
  font-weight: $fWeight600;

  color: $gray-800;
}

.intro_plus_icon {
  margin-left: auto;
  align-self: baseline;
}

// Temporary remove border and padding
// .intro_item_description_container {
//   padding-bottom: 8px;
//   border-bottom: $border_black_opacity_30;
// }

.intro_item_description_text {
  font-size: $font12;
  font-weight: $fWeight400;
  color: $gray-600;
  line-height: $line_height_18;
  margin: 0;

  &.three_lines_text_ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.intro_item_example_container {
  width: 100%;
}

.intro_item_example_title {
  font-size: $font12;
  font-weight: 600;
  color: $gray-600;
}

.intro_item_example_text {
  font-size: $font12;
  font-weight: $fWeight400;
  color: $gray-600;
  line-height: $line_height_18;
  &.two_lines_text_ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.see_more_btn {
  width: 100px;
  font-weight: $fWeight500;
  color: $gray-600;
  line-height: $line_height_18;
  font-size: $font18;
  text-decoration: underline;
  width: fit-content;
  background-color: transparent;
  border: none;
}
