 footer {
     background-color: $primary;
     padding: 28px 0;
     .media {
         display: flex;
         align-items: center;
         justify-content: space-between;

         p {
             font-size: $font14;
             @include Montserrat (500);
             color: $white;
         }

         span {
             display: flex;
             font-size: $font14;
             @include Montserrat (600);
             color: $white;

             a {
                 color: $white;
                 text-decoration: underline;
                 display: inline-block;
                 margin-left: 5px;
             }
         }
     }
 }