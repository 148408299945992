@import 'node_modules/react-datepicker/src/stylesheets/datepicker.scss';
@import '../variables';

.react-datepicker__input-container {
  input {
    border-radius: 8px;
    height: 42px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 166.5%;
    color: #212121;
    border: 1px solid #d6dade;
  }
}

.react-datepicker {
  border-radius: 16px;
  border: none;
  height: 300px;
  background-color: #f5f5f5;
  z-index: $z_index_fourth_floor;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  .react-datepicker__month-year-dropdown {
    height: 250px;
    overflow: auto;
  }

  .react-datepicker__month-select,
  .react-datepicker__year-select {
    border: none;
    border-radius: 6px;
  }

  .react-datepicker__day-name {
    color: #808080;
    text-shadow: 2px 2px 8px rgba(16, 16, 16, 0.24);
  }

  .react-datepicker__time {
    background: inherit;
  }

  .react-datepicker__current-month {
    display: none;
  }

  .react-datepicker__navigation {
    background-color: #e8e8e8;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 8px 16px;
    display: none;

    .react-datepicker__navigation-icon {
      margin: auto;
      margin-top: 2px;
    }

    .react-datepicker__navigation-icon--next {
      margin-left: 15px;
    }

    .react-datepicker__navigation-icon--previous {
      margin-right: 15px;
    }
  }

  li.react-datepicker__time-list-item--selected {
    background-color: $primary !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: $primary;
    color: $bodyBg !important;
    border-radius: 50%;
    box-shadow: 2px 2px 8px 0px $text-color;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: $primary !important;
  }

  .react-datepicker__input-time-container {
    display: flex;
    justify-content: center;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    border: none !important;
    background-color: transparent;
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      font-size: $font18;
      font-weight: 700;
      color: $text-color;
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    font-size: $font16;
    font-weight: 400;
    color: $text-color;
  }
}
