.testimonial-box {
  display: flex;
  margin: 15px 0;

  img {
    height: unset !important;
  }

  .image-holder {
    max-width: 270px;
    width: 100%;
    min-height: 270px;
    font-size: 140px;
  }

  .text-box {
    padding-left: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h4 {
      font-size: $font16;
      color: $black;
      margin: 0 0 7px 0;
      @include Montserrat(700);
    }

    span {
      display: block;
      color: $black;
      font-size: $font14;
      @include Montserrat(700);
      margin: 0 0 25px 0;
    }

    p {
      display: flex;
      margin: 0 0 15px 0;

      a {
        font-size: $font14;
        color: $black;
        @include Montserrat(500);
        display: block;
      }

      strong {
        @include Montserrat(600);
        display: block;
        font-size: $font14;
        color: $black;
      }
    }

    ul {
      display: flex;
      align-items: center;

      li {
        margin-right: 15px;

        &:last-child {
          margin: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $secondary_color;

          svg {
            width: 24px;
            height: 24px;

            path {
              fill {
                color: $secondary_color;
              }
            }
          }

          &:hover {
            color: $primary_color !important;
          }
        }
      }
    }
  }
}
