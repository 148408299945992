.warning_message_container {
  min-height: 32px;
  display: flex;
  align-items: center;
  background-color: $warning_50;
  border-radius: $secondary_border_radius;
  gap: 12px;
  padding: 4px 10px 4px 4px;
  mix-blend-mode: multiply;
  width: fit-content;
  white-space: nowrap;
}

.warning_text {
  font-weight: $fWeight500;
  font-size: $font14;
  font-family: $font_family_messages !important;
  color: $warning_700;
}

.warning_text_link {
  text-decoration: underline;
  font-weight: $fWeight500;
  font-size: $font14;
  font-family: $font_family_messages !important;
  color: $warning_700;

  &:hover {
    text-decoration: underline;
    font-weight: $fWeight500;
    font-size: $font14;
    font-family: $font_family_messages !important;
    color: $warning_700;
  }
}

@media (max-width: 745px) {
  .warning_message_container {
    white-space: normal;
  }
}
