.step_one_heading3 {
  margin-bottom: 16px !important;
}

.step_one_agent_select {
  margin-bottom: 24px !important;
}

.format_text {
  font-size: 14px;
  font-weight: 500;
  line-height: 166.5%;
  color: #242d35;
  position: absolute;
}

.format_text_sizes {
  margin-top: 14px;
}

.postal_code_margin {
  margin-bottom: 39px;
}
