* {
  font-family: $font_family_dashboard_main !important;
}

.profileCard {
  background: $white;
  border: 2px solid $border8;
  @include border-radius(12px);
  padding: 24px;
  max-width: 800px;
  margin-bottom: 22px;

  .profileInfo {
    display: flex;

    .avatar {
      width: 124px;
      height: 124px;
      margin-right: 16px;

      img {
        width: 100%;
      }
    }

    h4,
    p {
      margin: 0;
    }

    .mediaBody {
      flex: 1;
    }
  }

  .desc {
    margin-bottom: 16px;

    h4 {
      @include Montserrat(500);
      font-size: $font14;
      line-height: 150%;
      color: $gray-800;
      margin: 0;
    }

    p {
      @include Montserrat(500);
      font-size: $font12;
      line-height: 150%;
      color: $gray-800;
      margin: 0;
    }
  }
}

.profileInfoCard {
  padding: 24px 16px;
  background: $white;
  border: 1px solid $border;
  @include border-radius(12px);

  .titleImg {
    text-align: center;
    margin-bottom: 23px;

    .avatar {
      width: 124px;
      height: 124px;
      @include border-radius(4px);
      margin: 0 auto 6px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include border-radius(4px);
      }
    }

    button {
      border: none;
      background: transparent;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      text-decoration-line: underline;
      color: $gray-800;
    }
  }

  .info {
    display: flex;
    padding: 8px 0;
    position: relative;

    .media-body {
      flex: 1;

      h4 {
        font-weight: 500;
        font-size: $font14;
        line-height: 150%;
        color: $gray-800;
        margin: 0px;
      }

      p {
        font-weight: 500;
        font-size: $font12;
        line-height: 150%;
        color: $gray-800;
        margin: 0;
      }
    }

    a,
    u {
      font-weight: 600;
      font-size: $font14;
      line-height: 150%;
      text-align: right;
      text-decoration-line: underline;
      color: $gray-800;
      cursor: pointer;
    }
  }
  .profile_edit_cancel {
    position: absolute;
    right: 0;
  }
}

.profile_img {
  font-size: 45px;
}

.profileInfoCard {
  .avatar {
    font-size: 45px;
  }
}

@include media-breakpoint-down(xl) {
  .cardProfile {
    margin-bottom: 8px;
  }
}

@include media-breakpoint-down(sm) {
  .profileCard .profileInfo {
    flex-direction: column;
  }
  .profileCard .profileInfo .avatar {
    margin-bottom: 16px;
  }
  .profileCard {
    padding: 16px;
  }
}
