.pdf_item {
  position: relative;

  height: 337px;
  width: 200px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  .img_part {
    position: relative;

    height: 283px;
    width: 100%;

    border-radius: $primary_border_radius;

    cursor: pointer;

    .cover_img {
      height: 283px;
      width: 100%;
      object-fit: cover;

      border-radius: $primary_border_radius;
    }
  }

  .img_part:hover + .pdf_cover {
    display: block;
  }

  .description_part {
    height: 100%;
    width: 100%;

    .description {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      font-size: $font14;
      font-weight: $fWeight500;
      line-height: 14px;
    }
  }

  .pdf_cover {
    display: none;
    position: absolute;

    height: 283px;
    width: 100%;

    padding: 16px;
    border-radius: $primary_border_radius;

    background-color: $black_60;

    &:hover {
      display: block;

      cursor: pointer;
    }

    .heading {
      display: flex;
      align-items: center;
      column-gap: 8px;

      .file_name {
        width: 128px;
        margin-bottom: unset;

        font-size: $font12;
        font-weight: $fWeight600;
        color: $white;

        white-space: pre-wrap; /* CSS3 */
        white-space: -moz-pre-wrap; /* Firefox */
        white-space: -pre-wrap; /* Opera <7 */
        white-space: -o-pre-wrap; /* Opera 7 */
        word-wrap: break-word; /* IE */
      }
    }
  }
}
