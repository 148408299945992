.subTotal {
  margin-bottom: 10px !important;
}

.order_summary_package_services_container {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  margin: 0 10px;

  @media (max-width: 480px) {
    flex-direction: column;
  }
}

.package_service_name {
  font-size: $font10 !important;
  display: flex;
  white-space: nowrap;
}

.pay_card_item {
  a {
    text-decoration: none;
  }
}
.services_list {
  padding: 9px 20px !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;

  .service_element {
    display: flex;
    flex-direction: row;
    gap: 8px;

    .service_border {
      width: 2px;
      background-color: #ddd;
      height: 14px;
    }
  }
}

.customize_section {
  margin-top: 8px;
  border-bottom: 1px solid #ddd;
  padding-left: 20px;

  .service_name_price {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .option_price {
      text-align: end;
    }
  }

  ul {
    li {
      border: none;
      padding: 8px 0;
    }
  }
}

.customize_title {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    font-size: $font14;
    line-height: 24px;
    color: $black;
    margin: 0;
  }
}

.package_total {
  padding: 7px 0;
  line-height: 14px;
}

.package_name {
  display: flex;
  white-space: nowrap;
  gap: 2px;

  &::first-letter {
    text-transform: uppercase;
  }

  @media (max-width: 480px) {
    flex-direction: column;
  }
}

.option_name {
  font-size: $font10 !important;
  display: flex;
  white-space: nowrap;
  flex: none !important;
}
