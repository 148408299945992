.close-btn {
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  @include border-radius(50%);
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translate(-50%, 0);
}

.modal-dialog {
  max-width: 796px;
  width: 100%;
}

.modal-content {
  background-color: transparent;
  border: none;
  border-radius: 0;

  .modal-body {
    padding: 0;
  }
}
video {
  width: 100%;
  height: 100%;
}
