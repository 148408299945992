.uploader_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999999999;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;

  .uploader_container {
    position: absolute;
    background: $white;
    width: 90%;
    top: 50px;
    height: 90%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include phone {
      width: 94%;
      top: 16px;
      height: 96%;
    }

    .uploader_header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid $bg-gray-3;
      padding: 16px 16px 8px;
      align-items: center;

      .title {
        font-size: 16px;
        line-height: 24px;
        color: $black-200;
        margin: 0;
        font-weight: bold;
        letter-spacing: 2%;
        font-family: Inter-Bold;
      }

      .uploader_btn_wrapper {
        .uploader_btn {
          border: none;
          background-color: $bg-gray-1;
          border-radius: 8px;
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .video_upload {
      height: inherit;
      padding: 15px;
      overflow: scroll;

      .video_upload_container {
        height: 100%;
        border: 1px dashed $bg-gray-3;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        .upload_btn {
          border: 1px solid $primary;
          color: $primary;
          background-color: $white;
          border-radius: 32px;
          margin-top: 8px;
          width: fit-content;
          height: fit-content;

          label {
            margin: 0;
            padding: 8px 20px 8px 12px;
            color: $primary;
            cursor: pointer;
          }

          span {
            margin-left: 11px;
            font-size: 14px;
            line-height: 14px;
          }
        }

        .upload_input {
          display: none;
        }

        .video_upload_content {
          width: 40%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          @include ipad {
            width: 75%;
          }
          @include phone {
            width: 98%;
          }

          .video_upload_title {
            font-size: 24px;
            color: $primary;
            line-height: 24px;
            margin: 24px 0 0;

            @include phone {
              font-size: 18px;
            }
          }

          .video_upload_text {
            font-size: 14px;
            line-height: 22px;
            color: $grey-100;
            margin: 8px 0 0;

            @include phone {
              line-height: 20px;
            }
          }
        }

        .show_videos {
          width: 100%;
          height: fit-content;
          display: flex;
          flex-wrap: wrap;

          .video_item_container {
            width: 213px;
            height: 160px;
            border: 1px solid;
            margin: 5px;
            padding: 4px;
            border-radius: 12px;
            border: 1px solid #d9d9d9;

            .video_item {
              width: 100%;
              height: 100%;
              border-radius: 12px;
              border: 1px solid;
              overflow: hidden;
              position: relative;

              .oval_spinner {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }

              .delete_item {
                display: none;
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: $black;
                opacity: 0.65;
                top: 0;
                left: 0;
                z-index: 20;

                .delete_icon {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  cursor: pointer;
                }
              }

              &:hover {
                .delete_item {
                  display: block;
                }
              }

              .video_display {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }

              .image_display {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
        }
      }
      .upload_btn_container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
      }

      .video_uploaded {
        align-items: unset;
        justify-content: unset;
      }
    }

    .uploader_footer {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      border-top: 1px solid $bg-gray-3;
      padding: 12px 16px;
      align-items: center;

      .footer_btn_wrapper {
        .uploader_cancel_btn {
          border-radius: 12px;
          background-color: $bg-gray-3;
          border: none;
          font-size: 14px !important;
          font-weight: 500;
          padding: 9px 16px;
          line-height: 22px;
          margin-right: 16px;
        }

        .uploader_save_btn {
          border-radius: 12px;
          background-color: $primary;
          color: $white;
          border: none;
          font-size: 14px !important;
          font-weight: 500;
          padding: 9px 16px;
          line-height: 22px;
          position: relative;

          .oval_spinner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          .hide_name {
            visibility: hidden;
          }
        }
      }
    }
  }
}

.videop_uploader_wrapper {
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  transform: translate(0) !important;
}
