.table-responsive{
    .table{
        tr{
            td,
            th{
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                text-align: justify;
                color: #4F5B67;
                vertical-align:middle;
            }
        }
    }
}