.informative_forward_container {
  min-height: 30px;
  width: fit-content;

  display: flex;
  column-gap: 8px;
  padding: 4px 4px 4px 12px;
  align-items: center;

  border-radius: $secondary_border_radius;

  background-color: $bg-gray-3;
  color: $primary;

  * {
    font-size: $font12 !important;
    font-weight: $fWeight500;
  }

  .informative_forward_text {
    margin-bottom: unset;
  }
}
