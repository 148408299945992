@import '../../../mixins.scss';

.video-container {
  .videos {
    width: 430px !important;
    height: 238px;
  }

  @include phone {
    width: 100%;
    .videos {
      width: 100% !important;
    }
  }
}

.matterport {
  margin-top: 2rem;
  overflow-x: hidden;

  .btn_wrapper {
    margin-right: unset !important;
    justify-content: unset !important;
    gap: 10px;

    @media (max-width: 1340px) {
      justify-content: space-between !important;
    }
    @media (max-width: 1290px) {
      margin-right: -15px !important;
    }
    @media (max-width: 1222px) {
      justify-content: unset !important;
    }
  }

  .main_information_of_matterport,
  .quantity_holder {
    padding-left: 60px;
    padding-right: 17rem;
    display: flex;
    flex-direction: inherit;
    align-items: flex-start;
    font-weight: 400;
    font-family: 'NunitoSans-Regular';
    font-size: 12px !important;
    font-style: normal;

    @include ipad {
      padding-left: 0;
      padding-right: 0;
      align-items: center;
    }

    .title_of_matterport {
      font-family: 'NunitoSans-Bold';
      font-style: normal;
      font-size: 2.5rem;
      line-height: 1.7rem !important;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      margin-bottom: 12px;
      padding-right: 3.6rem;
      color: #000000;
    }

    .video_subtitle {
      display: none;
    }

    .videos_address_holder {
      padding-top: 5px;
    }
  }
  @include ipad {
    .grid-container {
      padding-right: 0 !important;
      display: flex;
      justify-content: center;
    }
  }
}

.embedLink {
  &:hover {
    background-color: #000000 !important;
    background: #000000 !important;
    color: $primary-color !important;
  }
}

.download_btn {
  &:hover {
    background-color: #000000 !important;
    background: #000000 !important;
    color: $primary-color !important;
  }
}

.grid-container {
  padding-left: 6rem;
  padding-right: 20rem;
  height: auto;
  display: flex;
  @media (max-width: 1335px) {
    flex-wrap: wrap;
  }

  .grid-item {
    max-width: 426px;
    display: flex;
    flex-direction: column;
    padding-top: 48px !important;
    font-size: 1rem;
    text-align: center;
    text-align: inherit;

    .video_title {
      font-family: 'NunitoSans-SemiBold';
      font-style: normal;
      font-size: 17.6px;
      line-height: 30px;
      padding-top: 1.25rem;
      color: #000000;
      font-weight: unset;
      @media (max-width: 991px) {
        padding-top: 16px;
      }
    }

    .description {
      font-family: 'NunitoSans-Regular';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.7);
      width: 90%;
      padding-bottom: 0.5rem;
    }

    .multi-liner {
      display: none;
    }
  }
}

.multi-liner_of_matterport {
  width: 71.9%;
  display: flex;
  border: 1px solid #e7e7e7;
  position: relative;
  left: 11%;
  margin-top: unset;
  top: 20px;
  margin-bottom: 50px;

  @media (max-width: 1200px) {
    display: none;
  }
}

@media (max-width: 1100px) {
  .matterport {
    display: flex;
    flex-direction: column;

    .main_information_of_matterport,
    .quantity_holder {
      padding-top: 6px;
      justify-content: center;
      flex-direction: column;

      .button_block {
        display: block;
        padding-top: 5px;
      }

      .title_of_matterport {
        font-size: 18px;
        margin-bottom: 0;
        line-height: 25px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-right: unset;

        .matterport_title,
        .slash {
          display: none;
        }
      }

      .video_subtitle {
        display: block;
        font-family: NunitoSans-Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 30px;
        text-align: center;
        text-transform: initial !important;
        color: rgba(0, 0, 0, 0.4);
        margin-bottom: -6px;
      }
    }
  }

  .grid-container {
    grid-template-columns: auto;
    height: auto;

    .video-container {
      align-self: center;
      justify-self: center;
      min-width: 426px;
      @include phone {
        display: contents;
      }
    }

    .grid-item {
      width: unset;
      padding: 0;

      .videos {
        border-radius: 0px;

        .video {
          width: auto;
        }
      }

      .video_title {
        font-size: 15px;
        line-height: 20px;
      }

      .quantity_text,
      .size {
        font-size: 10px !important;
        line-height: 16px;
      }

      .description {
        font-size: 12px;
        line-height: 16px;
      }

      .learn_more {
        display: none;
      }
    }

    .download_btn {
      justify-content: center;
      font-size: 12px;
      line-height: 16px;
      width: 150px;
      height: 35px;

      > span {
        font-size: 16px;
      }
    }

    .matterport {
      display: none;
    }

    .small-liner {
      display: block;
      width: 84%;
      margin-left: 12rem;
      border: 1px solid #e7e7e7;
      display: flex;
    }

    .multi-liner {
      display: block !important;
      width: 100%;
      position: relative;
      left: 0;
      border: 1px solid #e7e7e7;
      margin-bottom: 15px;
    }

    .multi-liner.unbranded-liner {
      margin-bottom: 0 !important;
    }
  }

  .grid-container {
    grid-template-columns: auto;
    height: auto;

    .grid-item {
      width: unset;

      .video_title {
        font-size: 15px;
        line-height: 20px;
        padding-left: 15px;
      }

      .quantity_text,
      .size {
        font-size: 10px !important;
        line-height: 16px;
        padding-left: 15px;
      }

      .description {
        font-size: 12px;
        line-height: 16px;
        padding-left: 15px;
      }
    }
  }
}

.video-container {
  @include ipad {
    .grid-item {
      padding-top: 0 !important;
    }
  }
}

@media (max-width: 500px) {
  .grid-container {
    padding-left: unset;
    padding-right: unset;

    .video-container {
      min-width: unset;
      align-self: unset;
      justify-self: unset;
    }

    .grid-item {
      margin-right: 0;
      padding-top: 6px;

      .size {
        padding-top: 15px;
      }
    }

    .download_btn {
      padding-right: 0px !important;

      > span {
        font-size: 16px;
      }
    }

    .multi-liner {
      width: 85.4%;
      left: 7.8%;
    }
  }
}

.copied_btn,
.download_btn {
  background-color: $primary-color !important;
  position: relative;

  > span {
    font-size: 16px;
  }

  @include ipad {
    padding-left: 2.5rem;
    margin-left: 1.5rem;
  }
  @include ipad {
    margin-left: 10px;
    padding-left: 10px;
    margin-top: 15px;
  }

  .liner_border {
    border-right: 1px solid $primary-color;
    width: 1px;
    height: 80%;
    margin: 0 10px 0 0;

    & :hover {
      border-right: 1px solid rgba(28, 28, 28, 1);
    }
  }

  &:hover {
    background-color: #000000;
    color: $primary-color !important;

    &::before {
      fill: #ffffff;
    }

    .liner_border {
      border-right: 1px solid $primary-color;
    }
  }

  &:nth-child(1) {
    display: flex;
    justify-content: center !important;
    align-items: center;
    @include ipad {
      padding-left: 20px;
      margin-left: 7px;
    }
    @include phone {
      padding-left: 0;
    }

    &::before {
      content: '';
      position: absolute;
      background-image: url('../../../../../assets/icons/copy-light.svg');
      background-size: cover;
      width: 16px;
      height: 16px;
      left: 10px;
      top: 50%;
      transform: translateY(-60%);
      @include not() {
        left: 10px;
      }
      @include ipad() {
        left: 10px;
        width: 14px;
        height: 14px;
      }
    }

    &:hover {
      &::before {
        // background-image: url("../images/copyHover.svg");
      }
    }
  }

  &:nth-child(2) {
    padding-left: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include ipad {
      padding-left: 25px;
    }
    @include phone {
      padding-left: 24px;
    }

    &::before {
      content: '';
      position: absolute;
      background-image: url('../../../../../assets/icons/embed-code-light.svg');
      background-size: cover;
      width: 20px;
      height: 10px;
      left: 10px;
      top: 50%;
      transform: translateY(-60%);
      @include not() {
        left: 10px;
      }
      @include ipad() {
        left: 10px;
        width: 17px;
        height: 10px;
      }
    }

    &:hover {
      &::before {
        // background-image: url("../images/arrowsHover.svg");
      }
    }
  }
}

.download_btn,
.copied_btn {
  width: 150px;
  height: 40px !important;
  font-size: 12px;
  line-height: 16px;
  outline: none;
  text-transform: uppercase;
  border: none;
  font-family: NunitoSans-Regular;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: flex-start !important;
  background-color: $primary-color !important;
  color: #000000;

  @include ipad {
    width: 150px;
    height: 35px !important;
    font-size: 12px;
    line-height: 16px;
  }

  &:hover {
    color: $primary-color !important;
    background-color: #000000;
    transition: all 0.3s ease-in-out;
  }
}

.copied_btn {
  background-color: #000000;
  color: #ffffff !important;
  align-items: center;

  &::before {
    left: 10px;
    width: 16px !important;
    height: 16px !important;
    background-image: url('../images/copiedURL.svg') !important;
  }

  &:nth-child(1) {
    margin-left: unset;
    align-items: center;

    @include phone {
      margin-left: 0;
    }
  }

  .liner_border {
    border-right: 1px solid #ffffff !important;
  }
}

.copied_btn,
.download_btn:nth-child(2) {
  margin-left: 2rem;
  display: flex;
  justify-content: center !important;

  @media (max-width: 1450px) {
    margin-left: 20px;
  }
  @media (max-width: 1340px) {
    margin-right: unset;
    margin-left: unset;
  }
  @media (max-width: 1100px) {
    margin-left: 10px;
  }
}

.download_btn:nth-child(1) {
  @media (max-width: 1222px) {
    padding-left: 0;

    .liner_border {
      margin: 0 10px 0 0;
    }
  }
}

.copied_btn {
  margin-top: 0;
  span {
    font-size: 12px !important;
  }
  @media (max-width: 1222px) {
    .liner_border {
      margin: 0 10px 0 -15px !important;
    }
  }
}
