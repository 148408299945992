.slide_item {
  position: relative;

  height: 100% !important;
  width: 100% !important;

  .image_slide {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.image_item {
  .img_part > .slick-slider {
    .slick-slide > div {
      padding: 0 0.1px !important;
    }
  }
}

.next_arrow_container {
  position: absolute;
  right: 0;

  height: 100%;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $z_index_fifth_floor;

  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  border-radius: 8px 0 0 8px;
  transform: rotate(-180deg);
}

.prev_arrow_container {
  position: absolute;
  left: 0;

  height: 100%;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $z_index_fifth_floor;

  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0) 100%
  );

  border-radius: 8px 0 0 8px;
}
