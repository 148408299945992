* {
  font-family: $font_family_dashboard_main !important;
}

.mediaTour {
  border: 1px solid $border;
  border-radius: 12px;
  padding: 16px;
  margin-top: 19px;
  position: relative;

  .mediatitle {
    display: flex;

    .img-holder {
      width: 40%;
      @include border-radius(10px);

      img {
        width: 100%;
        @include border-radius(10px);
      }
    }

    .media-content {
      flex: 1;
      padding-left: 16px;

      h3 {
        @include Montserrat(600);
        font-size: $font18;
        line-height: 150%;
        color: $gray-800;
        margin-bottom: 4px;
        a {
          text-decoration: none;
          color: $gray-800;
        }
      }

      h4 {
        margin-bottom: 0;
        @include Montserrat(600);
        font-size: $font14;
        line-height: 150%;
        color: $gray-800;
      }

      .order_id_title {
        margin-top: 4px;
        .lounge_id {
          color: #242d35;
          @include Montserrat(400);
        }
      }

      .user {
        display: flex;
        align-items: center;
        font-size: 14px;

        .avatar {
          width: 21px;
          height: 21px;
          border: 2px solid #07364b;
          @include border-radius(50%);
          margin-right: 4px;

          img {
            width: 100%;
            height: 100%;
            @include border-radius(50%);
            vertical-align: revert;
          }
        }

        .media-body {
          flex: 1;
          @include Montserrat(400);
          font-size: $font14;
          line-height: 150%;
          color: $gray-800;
        }
      }
    }
  }

  .tour-more-container {
    position: relative;

    .more-horiz-icon {
      cursor: pointer;
    }
  }

  .badgesList {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: -16px;
    right: 16px;

    li {
      margin-left: 12px;

      span {
        display: inline-flex;
        align-items: center;
        @include border-radius(12px);
        font-weight: 500;
        font-size: $font12;
        line-height: 100%;
        padding: 5px 10px;

        &.badgeRed {
          background: $danger;
          color: $white;
        }

        &.badgeWarning {
          background: $warning;
          color: $white;
        }

        &.badgeDarkWarning {
          background: $darkWarning;
          color: $white;
        }

        &.badgeOutlineDanger,
        &.badges-pending {
          border: 1px solid $danger;
          color: $danger;
        }

        &.badgeDanger,
        &.badges-requested {
          color: $white;
          background: $danger;
        }

        &.badgeDefault {
          background: $light;
          color: $gray-800;
        }

        &.badgeSuccess {
          background: $success;
          color: $white;
        }
      }
    }
  }
}

.orderTags {
  border: 1px solid $light;
  @include border-radius(8px);
  padding: 4px 10px;
  margin-bottom: 8px;

  &.tagDanger,
  &.orderTags-requested {
    border: 1px solid $danger;
  }

  &.tagWarning,
  &.orderTags-scheduled,
  &.orderTags-edited {
    border: 1px solid $warning;
  }

  &.tagSuccess,
  &.orderTags-delivered,
  &.orderTags-completed {
    border: 1px solid $success;
  }

  h4 {
    margin: 0;
    @include Montserrat(600);
    font-size: $font14;
    line-height: 150%;
    color: $gray-800;
  }

  p {
    margin: 0;
    @include Montserrat(400);
    font-size: $font14;
    line-height: 150%;
    color: $gray-800;
  }
}

.media-cta {
  display: flex;

  .media-body {
    flex: 1;
    padding-right: 15px;

    ul {
      li {
        margin: 8px 0;
        text-align: center;

        .btn {
          height: 40px;
          width: 180px;
          @include Montserrat(400);
          font-size: $font14;

          i {
            margin-right: 8px;
          }

          &:disabled {
            opacity: 0.3;
          }
        }

        .note {
          display: inline-flex;
          background: $bg-gray;
          border: 1px solid $bg-gray;
          @include border-radius(4px);
          @include Montserrat(500);
          font-size: $font12;
          line-height: 100%;
          color: $body-color;
          padding: 5px 10px;
        }

        a {
          list-style: none;
          text-decoration: none;
          white-space: nowrap;
        }
      }
    }
  }

  .cta-analytics {
    margin-left: auto;
    @include Montserrat(500);
    font-size: $font14;
    line-height: 150%;
    color: $primary;
    text-decoration: none;

    i {
      margin-left: 15px;
    }
  }
}

.captionForm {
  margin-bottom: 16px;
  gap: 4px;
  display: flex;
  flex-direction: column;

  h4 {
    @include Montserrat(500);
    font-size: $font14;
    line-height: 150%;
    color: $gray-800;
    margin: 0;
  }

  p {
    @include Montserrat(300);
    font-size: $font12;
    line-height: 150%;
    color: $gray-800;
    margin: 0;
  }
}

.selectSizeList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -20px 24px;

  li {
    padding: 3px 20px;
    position: relative;

    div {
      display: inline-flex;
      align-items: center;
      min-width: 131px;
      border: 1px solid #d6dade;
      border-radius: 5px;
      height: 36px;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      color: rgba(0, 0, 0, 0.6);
      padding: 4px 10px;
      cursor: pointer;

      i {
        width: 30px;
        height: 30px;
        margin-right: 5px;
        text-align: center;

        img {
          max-width: 100%;
        }
      }

      span {
        flex: 1;

        small {
          display: block;
        }
      }
    }

    &:after {
      content: '';
      width: 40px;
      position: absolute;
      top: 50%;
      transform: translate(50%, -50%);
      right: 0;
      height: 3px;
      background: #d2d2d2;
    }

    &:last-of-type {
      &:after {
        content: none;
      }
    }

    &.active {
      div {
        background: $primary;
        color: $white;
        border-color: $primary;

        i {
          img {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
    &.sq_ft_animation_active {
      div {
        animation: mainPulseAnimation 2s infinite;
      }
    }
  }
}

.labelList {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2px;

  li {
    padding: 4px 2px;
    position: relative;
    cursor: pointer;

    .iconInfo {
      position: absolute;
      top: 3px;
      right: -12px;
    }

    .dropdown-menu {
      padding: 0 !important;
      margin: -3px 0 0 !important;
      min-width: 100%;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.36);
      transform: inherit !important;
      border-radius: 12px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      top: 100% !important;

      li {
        margin: 0;
        padding: 0;

        .item {
          display: block;
          padding: 4px 10px;
          border-bottom: 0.5px solid rgba(0, 0, 0, 0.36);
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          color: #000000;
        }
        .last {
          display: block;
          padding: 4px 10px;
          border-bottom: unset;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          color: #000000;
        }
      }
    }

    ul {
      height: 262px;
      max-height: 262px;
      overflow-y: auto;
      li {
        margin: 0;
        padding: 0;

        .item {
          display: block;
          padding: 4px 10px;
          border-bottom: 0.5px solid rgba(0, 0, 0, 0.36);
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          color: #000000;
        }

        .label-select {
          border: none;
          background: transparent;
          width: 15px;
          overflow: hidden;
          &:focus {
            outline: none;
          }
          .last {
            display: block;
            padding: 4px 10px;
            border-bottom: unset;
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
            color: #000000;
          }
        }
      }
    }
  }
  .white-label {
    color: #fff;
  }
}

.label-select {
  border: none;
  background: transparent;
  width: 15px;
  overflow: hidden;
  &:focus {
    outline: none;
  }
}

.white-label {
  color: #fff;
}

.cta-package {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 42px;
  border: 1px solid #d6dade;
  border-radius: 4px;
  padding: 12px 16px;
  background: transparent;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #4f5b67;

  span {
    flex: 1;
    text-align: left;
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &[aria-expanded='true'] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.pagination-container {
  display: flex;
  justify-content: center;

  @include phone {
    max-width: 100%;
  }
}

.media {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ul {
    margin-left: unset !important;
  }

  .media-body {
    flex: 1;

    h4 {
      margin: 0;
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      color: #4f5b67;
    }

    p {
      margin: 0;
      font-weight: 400;
      font-size: 11px;
      line-height: 150%;
      color: #4f5b67;
    }
  }

  .price {
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #4f5b67;
  }
}

.packageDropdown {
  position: relative;
  .rotate {
    transition: all 0.2s;
    transform: rotate(180deg);
  }
  .unrotate {
    transition: all 0.2s;
    transform: rotate(0deg);
  }
  .dropdown-menu {
    min-width: 100%;
    max-height: 262px;
    overflow-y: auto;
    border: 1px solid #d6dade;
    margin-top: -2px !important;
    padding: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    position: absolute;
    background: white;
    z-index: $z_index_second_floor;

    li {
      padding: 5px 0;
      border-top: 0.5px solid rgba(0, 0, 0, 0.36);

      .item {
        display: block;
        padding: 16px 20px;

        &:hover {
          background: #e9e9e9;
        }

        .media {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          .media-body {
            flex: 1;

            h4 {
              margin: 0;
              font-weight: 600;
              font-size: 12px;
              line-height: 150%;
              color: #4f5b67;
            }

            p {
              margin: 0;
              font-weight: 400;
              font-size: 11px;
              line-height: 150%;
              color: #4f5b67;
            }
          }

          .price {
            font-weight: 500;
            font-size: 12px;
            line-height: 150%;
            color: #4f5b67;
          }
        }
      }
    }
  }
}

.packageStep {
  .cardCharge {
    background: transparent;

    &.cstWidth {
      max-width: 425px;
    }

    .note {
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: $gray-800;
    }
  }
}

.more_services_container {
  margin-bottom: 36px;
}

.infoMessage {
  max-width: 463px;
  min-width: 320px;
  border: 1px solid $danger;
  background-color: #f5f5f4;
  border-radius: 18px;
  padding: 24px 16px;
  height: auto;

  .infoHead {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .infoTitle {
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      font-size: 16px;
      color: $black;
      margin-left: 8px;
    }
  }

  .infoText {
    font-size: 14px;
    font-weight: $fWeight400;
    font-family: 'Montserrat', sans-serif;
    line-height: 22px;
    margin: 0;
  }
}

.paymentInfo {
  .checkbox-style {
    + {
      label {
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        color: #a8b0b9;
        margin-bottom: 0 !important;

        &::before {
          margin-right: 8px;
          top: -5px;
        }
      }
    }
  }
}

.filterSuggest {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 0 12px;
  row-gap: 5px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.36);
  margin-bottom: 12px;

  li {
    span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 7px 10px;
      height: 24.9px;
      border: 1px solid #c4c4c4;
      border-radius: 100px;
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      color: #000000;

      div {
        margin-left: 10px;
      }

      &.badge-danger-fill,
      &.badge-warning-fill,
      &.badge-success-fill {
        div {
          img {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }
}

.dropdown_container {
  position: absolute;
  width: 100%;
  height: auto;
  left: -1px;
  top: 26px;
  background-color: $white;
  border-radius: 0px 0px 12px 12px;
  padding-bottom: 10px;
  border: $primary_border;
  box-sizing: content-box;
  border-top: none;
  z-index: $z_index_second_floor;
}

.label_select_btn {
  border: none;
  background: transparent;
  padding: 0;
  display: flex;
}

.open_ctaService {
  border-radius: 12px 12px 0 0 !important;
}

.label_option {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.36);
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  font-size: 12px;

  &:hover {
    color: $white;
    background: $primary;
  }
}

.existing_tours_search_container {
  position: relative;
}

@include media-breakpoint-down(xxl) {
  .mediaTour .mediatitle .media-content h3 {
    font-size: 16px;
  }

  .orderTags h4,
  .orderTags p,
  .mediaTour .mediatitle .media-content .user .media-body {
    font-size: 12px;
  }

  .media-cta .media-body ul li {
    margin: 5px 0;
  }

  .media-cta .media-body ul li .btn {
    line-height: 1;
  }
}

.footerCta {
  margin: 0 -8px;
  display: flex;
  justify-content: flex-end;

  .btn {
    margin: 0 8px;
  }
}

.loungeTourButtons {
  width: 100%;
  margin: 0;
  background: white;
  position: sticky;
  bottom: 0;
}

.date_picker_label {
  white-space: nowrap;
}

@include media-breakpoint-down(xl) {
  .navigationControl {
    padding: 16px;
  }

  .mediaTour .mediatitle {
    margin-bottom: 16px;
  }

  .cardCharge {
    margin-bottom: 30px;
  }
}

@include media-breakpoint-down(xl) {
  .selectSizeList li {
    padding: 2px 8px;
  }

  .selectSizeList li:after {
    width: 16px;
  }
}

@include media-breakpoint-down(lg) {
  .map {
    margin-top: 20px;
  }

  .selectSizeList li:after {
    content: none;
  }
}

@include media-breakpoint-down(md) {
  .media-cta .media-body ul li {
    text-align: left;
  }
}

@include media-breakpoint-down(sm) {
  .mediaTour .mediatitle {
    flex-direction: column;
  }

  .mediaTour .mediatitle .img-holder {
    width: 100%;
    margin-bottom: 15px;
  }

  .mediaTour .mediatitle .media-content {
    padding: 0;
  }

  .footerCta .btn {
    min-width: 145px;
    height: 40px;
    font-size: 14px;
  }

  .dropdownMenu {
    min-width: 300px;
  }
}

@media (max-width: 400px) {
  .footerCta .btn {
    font-size: 12px;
  }

  .footerCta {
    margin: 0 -3px;
  }

  .loungeTourButtons {
    margin: 0;
    justify-content: space-evenly;
  }

  .footerCta .btn {
    margin: 0 3px;
  }
}
