@import '../../mixins.scss';

.modal_cover_section_of_video {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 998;
  background: rgba(0, 0, 0, 0.6);
  display: flex;

  .video_modal_section {
    width: 85%;
    margin: 0 auto;

    .video_of_modal_section {
      position: fixed;
      background: transparent;
      margin: 0 auto;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include ipad {
        min-width: 600px;
      }
      @include phone {
        min-width: unset;
        max-height: 240px;
      }

      @media (max-width: 991px) {
        left: 50%;
      }

      .image_modal {
        height: 100%;
        width: 100%;
      }

      @include phone {
        width: 83%;
      }
      .video_grabber {
        position: relative;
        max-width: 100%;
        overflow: hidden;
        padding-top: 56%;

        .video_of_watch_preview {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100% !important;
          height: 100% !important;
          border: none;
        }
      }

      .remove_padding {
        padding: 0;

        .video_container {
          display: flex;
          justify-content: center;
        }

        .modal_iframe {
          max-width: 700px;
          border-radius: 12px;

          iframe {
            border-radius: 12px;
          }

          @include phone {
            width: 352px;
          }
        }
      }

      .close_section {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        top: -21px;
        z-index: 4;
        @include phone {
          top: -50px;
        }
      }
    }

    .video_modal_center {
      left: 50%;
    }
  }
}

.priority_high {
  z-index: 100000000;
}

.hide_modal {
  display: none;
}
