 header {
     border-bottom: 1px solid rgba($white, 0.36);
     padding: 32px 0 0 0;
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     z-index: 10;

     .navigation {
         .navbar {
             background-color: transparent;
             padding: 0;

             .navbar-nav { 
                 width: 100%;
                 justify-content: space-between;

                 .nav-item {
                     padding: 0 20px; 
                    
                     &:first-child{
                        padding-left: 0;
                     }
                     &:last-child{
                        padding-right: 0;
                     }
                     .nav-link {
                         color: rgba($white, 0.6);
                         @include Montserrat(600);
                         padding: 0 0 30px 0;
                         position: relative;
                         font-size: $font14;

                         &.active {
                             color: rgba($white, 1);

                             &::after {
                                 width: 100%;
                             }
                         }

                         &::after {
                             position: absolute;
                             content: "";
                             left: 0;
                             bottom: -1px;
                             width: 0;
                             background-color: $white;
                             height: 1px;
                         }

                     }
                 }
             }
         }
     }
 }