@import "../../mixins.scss";

.download_btn_component {
  width: 150px;
  height: 40px;
  font-size: 12px;
  line-height: 16px;
  outline: none;
  border: none;
  font-family: NunitoSans-Regular;
  transition: 0.3s linear;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
  background-color: $primary-color !important;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;

  &.loading {
    background-color: #0f74a8;
  }

  @include ipad {
    font-size: 12px;
    line-height: 16px;
    width: 135px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    color: $primary-color !important;
    background-color: #000000 !important;
  }

  &:disabled {
    background-color: #cccccc;
    justify-content: center;
  }

  &:disabled:hover {
    background-color: #cccccc;
    color: #000000;
    cursor: not-allowed;
  }
}
