.loungePackagesWrapper {
  display: grid;
  row-gap: 40px;
  margin-top: 40px;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  .loungePackage {
    background-color: $primary-bg;
    border: $primary-bg-border;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    padding: 16px;

    @include ipad {
      padding: 16px 17px;
    }

    @include phone {
      flex-direction: column;
      padding: 16px;
    }

    .loungeVideo {
      width: 153px;
      min-height: 287px;
      max-height: 310px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      @include phone {
        width: auto;
        margin: auto;
      }

      .posterImage {
        height: 100%;
        width: 97%;
        max-width: 153px;
        object-fit: cover;
        border-radius: 25px;
      }

      .play_button {
        position: absolute;
        width: 31px;
        height: 31px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .mockup_image {
        position: absolute;
        top: 0;
        height: 0;
        width: 100%;
        height: 100%;
      }
    }

    .loungeContext {
      width: 74%;

      @include phone {
        width: 100%;
        margin: 15px 0;
      }

      .loungeContextHeader {
        display: flex;
        justify-content: space-between;
        .loungePrice {
          color: $black-light;
          font-size: 18px;
          @include Montserrat(700);
        }

        .showDesktop {
          display: block;

          @include phone {
            display: none;
          }
        }
      }

      .loungeTitle {
        font-size: 15px;
        @include Montserrat(600);
      }

      .loungeText {
        font-size: 12px;
        color: $gray-700;
        @include Montserrat(400);
      }

      .loungeInfoSection {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: 15px;
          @include Montserrat(600);
        }

        p {
          margin-bottom: 8px;
          font-size: 12px;

          strong {
            font-size: 15px;
            @include Montserrat(600);
            font-weight: 600 !important;
          }
        }

        ul {
          margin-left: 20px;
          li {
            list-style: disc;
            color: $gray-700;
            font-size: 12px;
            @include Montserrat(400);

            &::marker {
              color: $gray-700;
            }
          }
        }
      }
    }

    .addContainer {
      margin-left: auto;
      .addBtn {
        padding: 12px 12px 12px 20px;
        border-radius: 32px;
        font-size: 14px;
        border: $primary_border;
        font-size: 14px !important;
        line-height: normal;
        @include Montserrat(500);
      }

      .addedBtn {
        color: $white;
        background-color: $primary;
      }

      .notAdded {
        color: $primary;
        background-color: transparent;
      }
    }

    .showMobile {
      display: none;

      @include phone {
        display: block;
      }
    }
  }

  @include ipad {
    grid-template-columns: 1fr;
  }
}
