@import '../../../mixins.scss';

.gallery_section {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 70px;
  padding-right: 70px;

  @include not {
    padding-left: 70px;
  }

  .main_info {
    display: flex;
    align-items: baseline;

    @include ipad {
      padding-top: 10px;
    }

    .title {
      padding-right: 3.2rem;
      position: relative;
      top: 0.3rem;
    }
  }

  .subtitles {
    .address {
      display: none;
    }
  }

  @include ipad {
    padding-left: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: unset;
    margin-top: 5px;
    padding-left: 70px;
  }

  @include phone {
    padding-left: 0;
  }

  .before_checkbox_section {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .before_checkbox_section input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    right: 10px;
    height: 17px;
    width: 17px;
    border-radius: 2px;
    background-color: transparent;
    border: 1.2px solid #ffffff;
    @include ipad {
      height: 13px !important;
      width: 13px !important;
    }
  }

  .before_checkbox_section input:checked + .checkmark {
    background-size: 60%;
    content: '';
    background-image: url('../feature-sheet/elements/checkmark.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    background-color: #ffffff;
  }

  .images_section {
    margin-right: 0;

    .scrollbar {
      display: flex;
      flex-wrap: wrap;
      column-gap: 10px;
      row-gap: 16px;
      overflow: auto;
      scroll-behavior: auto;
      max-height: 600px;

      @include ipad {
        column-gap: 2px;
        row-gap: 2px;
      }
    }

    .force-overflow {
      min-height: 450px;
    }

    .scrollbar-default::-webkit-scrollbar {
      width: 4px;
      border-radius: 5px;
      background-color: #e6e6e6;
    }

    .scrollbar-default::-webkit-scrollbar-thumb {
      border-radius: 5px;
      -webkit-box-shadow: inset 0 0 6px #bbbaba;
      background-color: #bbbaba;
    }

    @include phone {
      margin-left: -1px;
      margin-right: 0.6px;
    }

    .image_section_item {
      width: 100%;
      width: 181.5px;
      height: 121.5px;
      position: relative;
      border-radius: 5px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @include ipad {
        width: 124px;
        height: 82px;
      }

      @include phone {
        padding: 0;
        margin-right: unset;
      }

      @include smallPhone {
        width: 30%;
      }

      .active {
        position: absolute;
        width: 80%;
        top: 10%;
        left: 10%;
        right: 10%;
        bottom: 10%;
      }

      img {
        width: 100%;
      }

      .hover_section {
        position: absolute;
        z-index: 9;
        top: 0px;
        left: 0px;
        right: 0;
        bottom: 0px;
        width: 100%;
        height: 100%;
        @include phone {
          top: 0px;
          left: 0px;
          right: 0px !important;
          bottom: 0px;
        }

        .checkmark_checkbox {
          position: absolute;
          z-index: 99;
        }

        .hide_checkbox {
          display: none;
        }

        .show_checkbox {
          display: block;
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.5);
          background-image: url('../../virtual-staging//svg/hover.svg');
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 5px;

          @include phone {
            background-image: unset;
          }

          img {
            width: 20%;
            display: block;
          }

          .hide_checkbox {
            display: block;

            @include phone {
              display: none;
            }
          }
        }

        img {
          display: none;
          position: absolute;
          z-index: 99;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 30px !important;
          height: 30px !important;
        }

        .before_checkbox_section {
          position: absolute;
          z-index: 99;
          right: 0px;
          bottom: 1.5rem;
          @include phone {
            bottom: 10px;
          }
        }
      }
    }
  }

  h2 {
    font-family: 'NunitoSans-Bold';
    color: rgba(0, 0, 0, 1);
    font-style: normal;
    font-size: 1.25rem;
    line-height: 3.4rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom: 0.6rem;
    margin-top: 2rem;

    @include ipad {
      margin-top: 5px;
      margin-bottom: 0;
      font-size: 18px;
      line-height: 25px;
    }
  }

  .quntity_text {
    font-family: 'NunitoSans-Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: rgba(65, 65, 65, 1);
    @include ipad {
      font-size: 10px;
      line-height: 14px;
    }

    .dot {
      @include ipad {
        padding-left: 3px;
        padding-right: 3px;
      }
    }
  }

  .gallery_button_section {
    margin-bottom: 4rem;
    @include ipad {
      margin-bottom: 20px;
      width: 100%;
    }

    .hide {
      display: none;
    }

    .select_item {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      font-family: NunitoSans-Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      margin-top: 10px;
      color: #414141;
      position: absolute;
      right: 17.6rem;

      @include ipad {
        margin-left: 15px;
        justify-content: flex-start;
        margin-top: 5px;
        font-size: 10px;
        line-height: 14px;
        left: 0%;
      }
      @include phone {
      }
      width: 90%;
    }
  }

  .btn-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    padding-right: 0.5rem;
    @include ipad {
      margin-top: 0;
      justify-content: flex-start;
    }

    @include phone {
    }

    > button {
      width: 150px;
      height: 40px;
      font-size: 12px;
      line-height: 1.6rem;
      outline: none;
      border: none;
      font-family: NunitoSans-Regular;
      text-transform: uppercase;
      transition: 0.3s linear;
      cursor: pointer;

      @include ipad {
        margin-top: 10px;
        width: 150px;
        height: 35px;
        font-size: 12px;
        line-height: 16px;
      }

      &:nth-child(2) {
        display: block;
        background-color: #000000;
        padding-left: 0 !important;
        margin-left: 15px;
        color: #ffffff;
        border-radius: 5px;

        @include ipad {
          display: none;
        }

        &:hover {
          color: $primary-color !important;
        }
      }

      &:nth-child(1) {
        margin-left: 15px;
        box-sizing: border-box;
        border: 1px solid #000000;
        color: #000000;
        background-color: transparent;
        position: relative;
        border-radius: 5px;

        &:hover {
          background-color: #000000;
          color: #ffffff;

          &::before {
            fill: #ffffff;
          }
        }
      }
    }

    .download_btn_wrapper {
      &:hover {
        .dowload_section {
          display: block;
        }

        .download_btn {
          color: $primary-color !important;
          background-color: #000000 !important;
          background: #000000 !important;
        }

        .download_btn_hide {
          color: #000000;
          background-color: $primary-color !important;
          border-radius: 5px;

          &::before {
            content: url('../images/arrowDown.svg');
          }

          &:hover {
            &::before {
              content: url('../images/arrowUp.svg');
            }
          }
        }

        .download_btn_hide {
          background-color: #000000;
          color: $primary-color !important;
          border-radius: 5px;

          &::before {
            content: url('../images/arrowDown.svg');
          }
        }

        .download_btn::before {
          content: url('../images/arrowUp.svg');
          position: absolute;
          right: 10px;
          width: 15px;
        }
      }

      .download_btn,
      .download_btn_hide {
        width: 150px;
        height: 40px;
        font-size: 12px;
        line-height: 1.6rem;
        outline: none;
        border: none;
        font-family: NunitoSans-Regular;
        transition: 0.3s linear;
        cursor: pointer;
        border-radius: 5px;
        position: relative;
        background-color: $primary-color !important;
        color: #000000;
        padding-left: 0 !important;
        margin-left: 15px;
        display: flex;
        justify-content: flex-end;
        padding-right: 21px;
        align-items: center;

        @include ipad {
          margin-top: 10px;
          height: 35px;
          width: 150px;
          margin-left: 10px;
          padding-right: 3.5rem;
          font-size: 12px;
          line-height: 16px;
        }

        .liner_border {
          border-right: 1px solid $primary-color;
          width: 1px;
          height: 80%;
          margin: 0 6px 0 20px;
          @include ipad {
            margin: 0 10px 0 15px;
          }
          @include phone {
            margin: 0 20px 0 15px;
          }
        }

        &::before {
          content: url('../images/arrowDown.svg');
          position: absolute;
          right: 6px;
          top: 50%;
          transform: translateY(-50%);
          width: 15px;
        }
      }

      .dowload_section {
        position: absolute;
        margin-left: 15px;
        width: 150px;
        display: flex;
        background: #ffffff;
        display: none;
        border-radius: 0px 0px 5px 5px;
        z-index: 99;

        @include not {
          font-size: 13px;
        }

        @include ipad {
          width: 150px;
          margin-left: 10px;
          z-index: 3;
        }

        .download_options {
          display: flex;
          flex-direction: column;

          span {
            font-family: NunitoSans-Regular;
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            line-height: 15px;
            display: flex;
            align-items: center;
            padding: 5px;
            cursor: pointer;
            color: #000000;

            &:hover {
              background: #ebebeb;
              border-radius: 0px 0px 5px 5px;
            }

            @include ipad {
              font-size: 11px;
              line-height: 15px;
            }
          }
        }
      }
    }
  }

  .gallery_liner {
    width: 100%;
    display: flex;
    border: 1px solid #e7e7e7;
    margin-bottom: 3.9rem;

    @include phone {
      margin-bottom: unset;
    }
  }
}

@media (max-width: 1025px) {
  .gallery_section {
    .main_info {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .title {
        padding-right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .delivery_update_button {
      padding-top: 5px;
    }

    .subtitles {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 48px;

      @include ipad {
        margin-bottom: 0;
      }

      .address {
        display: block;
        font-size: 10px;
        line-height: 14px;
        padding-top: 6px;
        padding-bottom: 3px;
      }

      .quntity_text {
        display: flex;
        width: 100%;
        justify-content: center;
      }
    }

    .images_section {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      margin-top: 5px;
      justify-content: space-evenly;
      @include phone {
        width: 100%;
      }

      .scrollbar {
        width: auto;
        max-height: initial;
        height: auto;
        margin-top: 10px;
        overflow-y: hidden;
        display: flex;
        justify-content: space-evenly;
      }
    }
  }
}

.download_btn.download_gallery_btn_disable {
  background: #cccccc !important;

  &:hover {
    background-color: #cccccc;
    color: #000000 !important;
    cursor: not-allowed;
  }
}

.download_options.download_options_hide {
  display: none !important;
}

.select_button_gallery_section {
  display: block;
  background-color: #000000 !important;
  padding-left: 0 !important;
  margin-left: 15px;
  color: #ffffff !important;
  border-radius: 5px;

  &:hover {
    color: $primary-color !important;
  }
}

.gallery_section_select_button_wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 180px;
  bottom: 50px;
  z-index: 1;
  width: 100%;
}

.photo_select_button_for_mobile_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20px;
}

.notification_element_for_download_button {
  position: absolute;
  font-size: 12px;
  color: #000000;
  line-height: 16px;
  z-index: 1000;
}

.image_name {
  position: absolute;
  bottom: 12.29px;
  left: 12px;
  font-family: Nunito Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
