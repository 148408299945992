.all-content {
  min-height: 600px;
  display: flex;
  align-items: baseline;
  background-color: #f4f4f4;
  padding-bottom: 24px;
  position: relative;
  padding-top: 20px;

  .download_section_title {
    font-size: 25px !important;
    font-family: "NunitoSans-Regular" !important;
    font-weight: 600;
    & > span {
      font-size: 25px !important;
    }
  }
}

@media (max-width: 1023px) {
  .all-content {
    display: block !important;
  }
}
