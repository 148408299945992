.copy_component,
.copy_component_first_btn {
  font-size: 25px !important;
}

@media (max-width: 1100px) {
  .copied_link_btn {
    padding-top: unset !important;

    .copy_component,
    .copy_component_first_btn {
      margin-left: -5px !important;
      font-size: 25px !important;
    }
  }
}
