.cstTabs {
  display: flex;

  li {
    flex: 1;

    a,
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: none;
      text-align: center;
      padding: 12px;
      border-bottom: 3px solid $gray;
      width: 100%;
      font-size: $font18;
      color: $gray;
      text-decoration: none;

      &.active {
        border-bottom: 3px solid $primary;
        color: $primary;
        @include poppins(600);
      }
    }
  }
}

.stepwizard {
  display: flex;
  align-items: center;

  li {
    padding-right: 60px;
    position: relative;

    div {
      text-decoration: none;
      @include Montserrat(500);
      font-size: $font14;
      line-height: 100%;
      color: $primary-opacity;
      padding: 11px 20px 11px 12px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: $white;
      border: 1px solid $border7;
      border-radius: 32px;
      margin: 0;
      height: 40px;
      position: relative;
      z-index: 1;
      cursor: pointer;

      i {
        margin-right: 8px;
      }

      svg {
        fill: $primary;

        g {
          opacity: 1;

          path {
            fill: $primary-opacity;
          }
        }
      }
    }

    &.active {
      div {
        color: $primary;

        border: $primary_border;

        svg {
          fill: $primary;

          g {
            opacity: 1;

            path {
              fill: $primary;
            }
          }
        }
      }
    }

    &.visited {
      div {
        color: $white;
        background: $primary;
        border: $primary_border;

        svg {
          fill: $white;

          g {
            opacity: 1;

            path {
              fill: $white;
            }
          }
        }
      }

      &:after {
        background: $body-color;
      }
    }

    &:after {
      content: '';
      width: 61px;
      height: 3px;
      background: $border7;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 0;
    }

    &:last-of-type {
      &:after {
        content: none;
      }
    }
  }
}

.tabsRounded {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -4px;
  padding: 0;
  list-style: none;

  .saveChanges {
    background-color: rgba(7, 54, 75, 0.4);
    color: $primary;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    @include Montserrat(500);
    font-size: $font14;
    color: $gray-900;
    text-decoration: none;
    border: 1px solid $primary;
    @include border-radius(32px);
    margin-left: auto;
    &:hover {
      cursor: pointer;
    }
  }

  li {
    padding: 0 4px;

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      @include Montserrat(500);
      font-size: $font14;
      color: $primary;
      text-decoration: none;
      border: 1px solid $primary;
      @include border-radius(32px);

      &.active {
        color: $white;
        background: $primary;
        pointer-events: none;
      }

      &.disabled {
        opacity: 0.3;
        pointer-events: none;
      }
    }
  }
}
@media (max-width: 1433px) {
  .tabsRounded li a {
    font-size: 13px;
  }
}

@include media-breakpoint-down(lg) {
  .stepwizard li div {
    font-size: 12px;
  }

  .stepwizard li {
    padding-right: 35px;
  }

  .stepwizard li:after {
    width: 35px;
  }
}

@include media-breakpoint-down(sm) {
  .stepwizard li:after {
    width: 10px;
  }

  .stepwizard li {
    padding-right: 10px;
  }

  .stepwizard li div {
    font-size: 9px;
    padding: 10px;
  }

  .tabsRounded li {
    padding: 3px;
  }
  .tabsRounded li a {
    padding: 6px;
    font-size: 12px;
  }
}

@media (max-width: 400px) {
  .stepwizard li div i {
    margin-right: 4px;
  }
  .stepwizard li:after {
    width: 3px;
  }
  .stepwizard li {
    padding-right: 3px;
  }
}
