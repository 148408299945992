.photo_loading_wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  .photo_loading_outer {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: transparent;
    padding: 3px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;

    .photo_loading_inner {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: transparent;

      svg {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    #tick-mark {
      position: absolute;
      display: inline-block;
      width: 40px;
      height: 40px;
      top: 0;
      left: 0;
    }
  
    #tick-mark::before {
      position: absolute;
      left: 8px;
      top: 45%;
      height: 25%;
      width: 3px;
      background-color: #44C13C;
      content: '';
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }
  
    #tick-mark::after {
      position: absolute;
      left: 9px;
      bottom: 13px;
      height: 3px;
      width: 45%;
      background-color: #44C13C;
      content: '';
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }
  }
}
