.agent_no_image_item {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: $primary_thick_border;
  border-radius: 50%;
  color: $primary;
  background-color: $light_blue_bg;
  font-weight: $fWeight500;
  font-size: 80%;

  .agent_no_image_text {
    margin-bottom: 0;
  }
}

.no_border {
  border: none;
}
