@import '../../mixins.scss';

$width: 100%;
$font-color: #282828;

.loading-page-background {
  background-color: #e5e5e5;
  position: fixed;
  width: $width;
  height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 55;

  .logo-container-loading {
    display: flex;
    justify-content: center;
    width: 100vw;
    padding-top: 22px;
  }

  .loading-logo-container {
    padding-top: 2.2rem;
    display: flex;
    align-items: flex-start;
    width: $width;
    justify-content: center;

    .property-vision-logo {
      @include ipad {
        padding-top: unset;
        margin-top: 15px;
        width: 130.3px;
        height: 25px;
      }
    }
  }

  .loading-main-content {
    width: $width;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .loading-page-logo {
      width: 2.8rem;
      padding-bottom: 1.89rem;

      @include ipad {
        padding-bottom: unset;
        margin-bottom: 20px;
        width: unset;
        height: 25px;
      }
    }

    .loading-page-title {
      font-family: 'NunitoSans', sans-serif;
      font-style: normal;
      font-size: 20px;
      font-weight: $fWeight700;
      line-height: 34px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: $font-color;
      padding-bottom: 1.2rem;

      @include ipad {
        font-size: 20px;
      }
    }

    .loading-page-address {
      padding-bottom: 2.5rem;
      font-family: 'NunitoSans', sans-serif;
      font-style: normal;
      font-size: 19px;
      font-weight: $fWeight600;
      line-height: 29px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: $font-color;
      font-weight: 500;

      @include ipad {
        padding-bottom: 35px;
        font-size: 19px;
        text-align: center;
      }
    }

    .preparing-media-text {
      padding-top: 1.25rem;
      font-family: 'NunitoSans', sans-serif;
      font-style: normal;
      font-size: 0.9rem;
      line-height: 25px;
      letter-spacing: 0.05em;
      color: $font-color;

      @include ipad {
        padding-top: 10px;
        font-size: 12px;
      }

      .one,
      .two,
      .three {
        opacity: 0;
        -webkit-animation: dot 1.3s infinite;
        -webkit-animation-delay: 0s;
        animation: dot 1.3s infinite;
        animation-delay: 0s;
      }

      .two {
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s;
      }

      .three {
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
      }

      @-webkit-keyframes dot {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      @keyframes dot {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }

  &.page_loader_wrapper_out {
    opacity: 0;
    transition: all 0.3s linear;

    &.page_loader_wrapper_hide {
      display: none;
    }
  }
}

.loading-page-background.animate_fadeOut {
  animation-delay: 700ms;
}
