.empty_page {
  width: 100%;
  margin-top: 41px;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    margin: 0;
  }

  .empty_page_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    width: 480px;
    height: 480px;
    position: relative;

    .background_image {
      position: absolute;
      top: 0;
      left: 0;

      .image {
        width: 100%;
        height: 100%;
      }
    }

    .empty_page_content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 21%;
      height: 21%;

      .icon_container {
        border-radius: 50%;
        background: linear-gradient(180deg, #f9fafb 0%, #edf0f3 100%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }
    }

    .no_order_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 64px;
      z-index: 3;

      @media (max-width: 480px) {
        padding: 0;
      }

      .empty_title {
        font-size: 24px;
        font-weight: 600;
        color: #101828;
        margin-bottom: 8px;
      }

      .empty_description {
        text-align: center;
        line-height: 20px;
        color: #475467;
        margin: 0;
      }

      .actions_field {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        margin: 24px 0 27px;

        .empty_btn {
          padding: 8px 18px;
          line-height: 24px;
          font-weight: 600;
          border: 1px solid #d0d5dd;
          border-radius: 99px;
          font-size: 16px;
          box-sizing: border-box;

          @media (max-width: 480px) {
            font-size: 14px !important;
          }
        }

        .see_our_work_btn {
          background-color: transparent;
        }

        .order_new_tour_btn {
          background-color: #beaf87;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          border-color: #beaf87;
          gap: 2px;
          padding: 8px 16px;
        }
      }
    }
  }
}
