.wrapper {
h1,
h2,
h3,
h5,
h6 {
    @include FiraSansCondensed(700);
}

    a:focus {
        outline: none;
        outline-offset: inherit;
    }

    button:focus {
        border: none;
        box-shadow: none;
        outline: none;
        border-color: transparent;
    }

    p {
        margin-bottom: 0;
    }

    ul li {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    ul {
        padding: 0;
        margin-left: auto;
    }

    .form-control:focus {
        box-shadow: none;
        outline: none;
        outline-offset: inherit;
    }

    .btn_center {
        display: block;
        margin: 0 auto;
    }
}

/*.wrapper {
    overflow: hidden;
}*/