.packages_services_container {
  display: flex;
  flex-direction: column;
}

.packages_container {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.later_charge_title {
  margin-bottom: 8px !important;
}

.later_charge_description {
  margin-bottom: 7px;
}

.existing_size_list {
  margin: 0 -10px 24px !important;
}
