 .btn-default {
     max-width: 249px;
     height: 46px;
     display: flex;
     align-items: center;
     justify-content: center;
     background-color: $primary_color;
     @include border-radius(8px);
     @include Montserrat(600);
     color: $white;
     font-size: $font15;

     &:hover {
         background-color: $secondary_color;
         color: $white;
     }
 }