@import "../../mixins.scss";

   .address {
    display: none;
}

@include phone {
    .address {
        display: block;
        font-family: "NunitoSans-Regular";
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 14px;
        color: #00000066;
    }
}
