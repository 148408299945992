@import '../../mixins.scss';

.download_header_page_info_wrapper {
  padding-left: 260px !important;

  @include ipad {
    padding-left: 400px !important;
  }
  @include phone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0 !important;
    margin-top: 50px;
  }
}

.download_header_page_info_wrapper.popup_info {
}

.download_header_page_info_download_icon {
  @include phone {
    margin-bottom: 10px;
  }
}

.download_header_page_info_download_icon.popup_info {
  display: none;
}

.download_header_page_info_line_icon {
  height: 1px;

  @include phone {
    margin-bottom: 0;
    position: relative;
    bottom: 10px;
  }
}

.download_header_page_info_line_icon.popup_info {
  display: none;
}

.download_header_page_info_title {
  font-style: normal;
  font-size: 25px;
  line-height: 34px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0.9rem 0 1rem 0;
  font-family: 'NunitoSans-SemiBold';
  @include phone {
    font-style: normal;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 20px;
    margin-top: 10px;
  }
}

.download_header_page_info_title.popup_info {
  margin-top: 20px;
  margin-bottom: 15px;
  color: #000;
}

.download_header_page_info_address_wrapper {
  font-style: normal;
  font-size: 21px;
  line-height: 29px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  margin-bottom: 0.2rem;
  @include phone {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    align-items: center;
  }
}

.download_header_page_info_first_address {
  padding-right: 5px;
  @include phone {
    font-style: normal;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    font-family: 'NunitoSans-SemiBold';
    padding-right: unset;
    text-align: center;
  }
}

.download_header_page_info_first_address.popup_info {
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #1a1a1a;
}

.download_header_page_info_second_address {
  @include phone {
    font-style: normal;

    font-size: 19px;
    line-height: 26px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    font-family: 'NunitoSans-SemiBold';
  }
}

.download_header_page_info_second_address.popup_info {
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(26, 26, 26, 0.4);
}

.download_header_page_info_agent_wrapper {
  display: flex;
  margin-bottom: 0.2rem;
  @include phone {
    margin-bottom: 5px;
  }
}

.download_header_page_info_agent {
  font-style: normal;
  font-family: 'NunitoSans-SemiBold', sans-serif;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.05em;
  margin-right: 7px;
  text-transform: uppercase;
  color: #ffffff;
  @include phone {
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffffb2;
  }
}

.download_header_page_info_agent.popup_info {
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.7);
  margin-right: 3rem;
}

.download_header_page_info_agent_name {
  font-style: normal;
  font-family: 'NunitoSans-Light', sans-serif;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  @include phone {
    font-style: normal;

    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffffb2;
  }
}

.download_header_page_info_agent_name.popup_info {
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.7);
  margin-right: 3rem;
}

.download_header_page_info_date_shoot_wrapper {
  display: flex;
  margin-bottom: 0.2rem;
  @include phone {
    margin-bottom: 5px;
  }
}

.download_header_page_info_date_shoot_name {
  font-family: 'NunitoSans-SemiBold';
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.05em;
  margin-right: 7px;
  text-transform: uppercase;
  color: #ffffff;
  @include phone {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffffb2;
  }
}

.download_header_page_info_date_shoot_name.popup_info {
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.7);
  margin-right: 3rem;
}

.download_header_page_info_date_shoot {
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  font-family: 'NunitoSans-Light';
  @include phone {
    font-style: normal;

    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffffb2;
  }
}

.download_header_page_info_date_shoot.popup_info {
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.7);
}

.download_header_page_info_delivery_date_wrapper {
  display: flex;
  @include phone {
    margin-bottom: 5px;
    align-items: center;
    justify-content: center;
  }
}

.download_header_page_info_delivery_date_icon {
  display: none;

  @include ipad {
    display: block;

    #clock {
      vertical-align: super;
    }
  }

  @include phone {
    display: flex;
  }
}

.download_header_page_info_delivery_date_name {
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.05em;
  margin-right: 7px;
  text-transform: uppercase;
  color: #ffffff;
  font-family: 'NunitoSans-SemiBold';
  @include phone {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffffb2;
    margin-left: 5px;
  }
}

.download_header_page_info_delivery_date_name.popup_info {
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(26, 26, 26, 0.7);
  margin-left: 5rem;
  margin-right: 3rem;
}

.download_header_page_info_delivery_date {
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  font-family: 'NunitoSans-Light';
  @include phone {
    font-style: normal;

    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffffb2;
  }
}

.download_header_page_info_delivery_date.popup_info {
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(26, 26, 26, 0.7);
}

.download_header_page_info_icons_wrapper {
  display: flex;
  column-gap: 1rem;
  @include phone {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 25px;
  }
}

.download_header_page_info_icons_wrapper.popup_info {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}

.download_header_page_info_icon {
  margin: 0.5rem 0;

  > img {
    height: 25px;
  }

  @include phone {
    margin-bottom: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px 10px 5px;
    > img {
      height: 20px;
    }
  }
}

.download_header_page_info_popup_icon {
  max-width: 52px;
  max-height: 24px;
}

.download_header_page_info_wrapper.animate__fadeIn {
  animation-duration: 2s;
}
