@media (max-width: 1399px) {
  header .navigation .navbar .navbar-nav .nav-item {
    padding: 0 15px;
  }
}

@media (max-width: 1300px) {
  .neighborhood-info-sec .text-box {
    padding: 90px 42px;
  }
}

@media (max-width: 1199px) {
  header .navigation .navbar .navbar-nav .nav-item {
    padding: 0 5px;
  }

  .banner-sec .banner-inner {
    padding: 150px 0 0 0;
  }

  .neighborhood-info-sec .text-box {
    padding: 50px 25px;
  }

  .property-detail-sec .text-box ul li {
    font-size: 12px;
  }
}

@media (max-width: 991px) {
  header .navigation .navbar .navbar-nav .nav-item .nav-link {
    font-size: 11px;
    padding: 0 0 15px 0;
  }

  header {
    padding: 20px 0 0 0;
  }

  .banner-sec .banner-inner {
    padding: 100px 0 0 0;
  }

  .highlights-sec .highlights-inner ul li {
    text-align: center;
    padding: 15px 20px;
  }

  .highlights-sec .highlights-inner ul li h4 {
    font-size: 14px;
  }

  .modal-dialog {
    max-width: 85%;
    width: 100%;
    margin: 0 auto;
  }

  .property-detail-sec .text-box h2 {
    font-size: 28px;
  }

  .property-detail-sec .text-box h3 {
    font-size: 16px;
    margin: 10px 0 10px;
  }

  .property-detail-sec .text-box p {
    line-height: 16px;
    font-size: 12px;
    margin: 0 0 15px 0;
  }

  .property-detail-sec .text-box ul {
    margin-right: 5px;
  }

  .property-detail-sec .text-box ul li:after {
    height: 2px;
    width: 14px;
  }

  .property-detail-sec .text-box ul li {
    font-size: 12px;
    padding: 0 0 0 20px;
    margin: 0 0 13px 0;
  }

  .testimonial-box .text-box {
    padding-left: 15px;
  }

  .testimonial-box .text-box span {
    margin: 0 0 15px 0;
  }

  .testimonial-box .text-box p {
    margin: 0 0 10px 0;
  }

  footer .media p {
    font-size: 12px;
  }

  footer .media span {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .container,
  .container-sm {
    max-width: 100%;
  }

  header {
    border: none;
    padding: 20px 5px;
  }

  .navbar-toggler {
    padding: 0;
    border: none;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-toggle-icon {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
  }

  .navbar-toggle-icon img {
    margin-right: 9px;
  }

  .navbar-collapse {
    position: absolute;
    left: 0;
    top: 30px;
    width: 100%;
    background-color: #07364b;
    display: none;
  }

  header .navigation .navbar .navbar-nav .nav-item {
    padding: 0;
  }

  header .navigation .navbar .navbar-nav .nav-item .nav-link {
    font-size: 14px;
    padding: 12px 20px;
  }

  .banner-sec {
    margin-bottom: 61px;
  }

  .banner-sec .banner-inner {
    padding: 0;
    position: static;
    margin: -50px 0 0 0;
  }

  .banner-sec .banner-inner .text-box {
    max-width: 100%;
    filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.15));
  }

  .highlights-sec {
    padding: 0 0 50px;
  }

  .highlights-sec .highlights-inner ul {
    flex-wrap: wrap;
  }

  .highlights-sec .highlights-inner ul li {
    width: 33.3%;
    padding: 15px 10px;
  }

  .highlights-sec .highlights-inner ul li h4 {
    font-size: 16px;
  }

  .matterport-inner {
    margin: 26px -15px 0 -15px;
  }

  .matterport-inner .play-btn img {
    width: 60px;
  }

  .open-house-sec .open-house-inner ul li {
    font-size: 14px;
  }

  .open-house-sec .open-house-inner ul li img {
    height: 25px;
  }

  .open-house-sec {
    padding: 52px 0;
  }

  .matterport2-sec .matterport-inner {
    margin-top: 0;
  }

  .gallery-sec {
    padding: 40px 0 30px 0;
  }

  .gallery-sec .gallery-inner ul {
    margin: 0 -2.5px 20px;
  }

  .gallery-sec .gallery-inner ul li {
    width: 33.3%;
    padding: 3px 2.5px;
  }

  .gallery-sec .gallery-inner {
    margin: 26px 0 0 0;
  }

  .property-detail-sec {
    padding: 50px 0 0;
  }

  .property-detail-sec .text-box h2 {
    font-size: 40px;
  }

  .property-detail-sec .text-box h3 {
    font-size: 18px;
    margin: 30px 0 12px;
  }

  .property-detail-sec .text-box p {
    line-height: 17px;
    font-size: 14px;
    margin: 0 0 30px 0;
  }

  .property-detail-sec .text-box .media {
    flex-direction: column;
  }

  .property-detail-sec .text-box ul {
    margin: 0;
  }

  .property-detail-sec .text-box ul li {
    font-size: 14px;
    padding: 0 0 0 30px;
    margin: 0 0 20px 0;
  }

  .property-detail-sec .text-box ul li:last-child {
    margin-bottom: 20px;
  }

  .property-detail-sec .text-box ul li::after {
    height: 3px;
    width: 20px;
  }

  .property-detail-sec .image-holder {
    margin: 0 -15px;
  }

  .property-detail-sec.neighborhood-info-sec {
    padding-top: 0;
  }

  .neighborhood-info-sec .text-box {
    padding: 30px 15px 10px;
  }

  .neighborhood-info-sec .image-holder iframe {
    height: 350px;
  }

  .floor-plans-sec {
    padding: 60px 0 30px;
  }

  footer {
    padding: 16px 0;
  }

  footer .media {
    align-items: flex-start;
    flex-direction: column;
  }

  footer .media p {
    font-size: 11px;
    margin-bottom: 13px;
  }

  footer .media span {
    font-size: 11px;
  }
}

@media (max-width: 575px) {
  .matterport-inner .play-btn img {
    width: 37px;
  }

  .gallery-sec .gallery-inner ul li {
    width: 50%;
  }

  .property-detail-sec .text-box h2 {
    font-size: 38px;
  }

  .testimonial-box {
    flex-direction: column;
  }

  .testimonial-box .image-holder {
    max-width: 100%;
    width: 100%;
  }

  .testimonial-box .image-holder img {
    width: 100%;
  }

  .testimonial-box .text-box {
    padding-left: 0;
    padding-top: 10px;
  }

  .nav-pills .nav-item {
    margin-right: 55px;
  }

  .nav-pills {
    width: 500px;
    padding: 0 0 20px 0;
  }
}

@media (max-width: 374px) {
  .highlights-sec .highlights-inner ul li h4 {
    font-size: 13px;
  }

  .highlights-sec .highlights-inner ul li strong {
    font-size: 12px;
  }

  .open-house-sec .open-house-inner ul li {
    font-size: 13px;
  }

  .property-detail-sec .text-box h2 {
    font-size: 30px;
  }
}

@include phone {
  .testimonial-box .image-holder {
    height: 360px !important;
    font-size: 200px;
    img {
      height: 360px !important;
    }
  }
}
