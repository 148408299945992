.pack_item_container {
  width: 232px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $pack_item_bg_color;
  border: 1px solid $package_item_color;
  border-radius: $primary_border_radius;
  padding: 16px 0 0;
  position: relative;
  &:hover {
    .hover_message_visible {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .package_title {
    color: $gray-800;
  }

  &.container_blur {
    .price,
    .package_title,
    .service_names_container,
    .select_btn,
    .sale_options {
      opacity: 0.6;
    }
  }

  .price,
  .package_title,
  .service_names_container,
  .select_btn,
  .sale_options {
    opacity: 0.6;
  }

  &:not(.container_blur) {
    cursor: pointer;

    &:hover {
      .price,
      .package_title,
      .service_names_container,
      .select_btn,
      .sale_options {
        opacity: 1;
      }
    }
  }

  .sale_options {
    background-color: $sale_bg_color;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;
    border-radius: 0 0 8px 8px;

    .sale_title {
      color: $sale_black;
      font-weight: 700;
      line-height: 20px;
      margin: 0;
    }

    .option_field {
      display: flex;
      flex-direction: row;
      border-radius: $primary_border_radius;
      border: $package_sale_border;
      padding: 8px;
      justify-content: space-between;
      cursor: pointer;
      margin: 0;

      .option_desc {
        .option_title {
          margin: 0;
          font-size: 14px;
          color: $sale_black;
          font-weight: 500;
          line-height: 20px;
        }

        .option_price {
          font-size: 11px;
          color: $sale_price_color;
          margin: 0;
        }
      }

      .checkbox_field {
        position: relative;

        .custom_checkbox {
          width: 16px;
          height: 16px;
          border: $primary_border;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          .checkbox_input {
            display: none;
          }
        }

        .checkbox_selected {
          background-color: $white;
        }
      }
    }

    .active_option {
      background-color: $package_sale_color;

      .option_desc {
        .option_title {
          color: $white;
        }

        .option_price {
          color: $sale_price_active;
          opacity: 0.7;
        }
      }
    }
  }
}

.last_pack_item {
  background-color: $sale_bg_color;
}

.container_active_blur {
  .price,
  .package_title,
  .service_names_container,
  .select_btn,
  .sale_options {
    opacity: 1;
  }
}

.selected_container {
  border: $primary_border;

  .price,
  .package_title,
  .service_names_container,
  .select_btn,
  .sale_options {
    opacity: 1;
  }

  .select_btn {
    background-color: $primary;
    color: $white;
  }
}

.service_names_container {
  width: 100%;
  display: flex;
  padding: 24px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 16px;
}

.price_content {
  position: relative;
  min-height: 35px;
  display: flex;
  justify-content: center;

  .price {
    font-size: 36px;
    font-weight: $fWeight700;
    color: $gray-800;
    transition: all 0.5s;
    line-height: normal;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    .package_price {
      white-space: nowrap;
      margin: 0;
    }

    .show_price {
      display: flex;
      flex-direction: row;
      gap: 8px;

      .line {
        height: 44px;
        width: 0.5px;
        background-color: rgba(0, 0, 0, 0.5);
      }

      .prices {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        color: #282828b2;
        font-weight: 600;

        div {
          white-space: pre;
        }
      }
    }
  }

  @keyframes priceUpDown {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    50% {
      transform: translateY(-20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .add_animation_up {
    animation-name: priceUpDown;
    animation-duration: 1s;
  }

  .no-price {
    text-align: center;
  }
}

.title_container {
  width: 100%;
  display: flex;
  gap: 8px;
  position: relative;

  p {
    margin-bottom: unset;
    color: $gray-800-8;
  }
}

.service_title {
  font-size: $font14;
  font-weight: $fWeight400;
  color: rgba(36, 45, 53, 0.8);
  line-height: 24px;
}

.service_title_dash {
  color: $primary;
  font-weight: $fWeight1000;
  position: absolute;
  left: -1px;
  top: -3px;
}

.select_btn {
  width: calc(100% - 32px);
  height: 48px;
  background-color: transparent;
  border: $select_btn_border;
  border-radius: 12px;
  margin: 16px 0;
  color: $primary;
  font-size: $font16 !important;
  font-weight: $fWeight700;
}

.package_title {
  font-weight: $fWeight700;
  font-size: $font16;
  line-height: 24px;
  margin-top: 16px;
}
