body {
  line-height: 1;
}

abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  // font-size: 100%;
  vertical-align: baseline;
  background: 0 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ul {
  list-style: none;
  color: #000;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  // font-size: 100%;
  vertical-align: baseline;
  background: 0 0;
  outline: 0 !important;
  transition: all 0.5s ease-in-out;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

.mb--95 {
  margin-bottom: -95px;
}

.react-confirm-alert-overlay {
  background: rgba(255, 255, 255, 0.5);
  .react-confirm-alert-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h1 {
    font-size: 18px;
    color: #000;
    margin: 0 0 7px 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
  }
}
