* {
  font-family: $font_family_dashboard_main !important;
}

.auth-success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
  > h3,
  > p {
    text-align: center;
  }
}
