.headerMobile{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    .toggleSidebar{
        margin-left: auto;
        background: transparent;
        border: none;
        outline: none;
        img{
            height: 24px;
        }
    }
}