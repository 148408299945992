.open_an_alert {
  display: none;
  padding: 8px 12px;
  border-radius: 8px;
  z-index: 30;
  position: absolute;
  background-color: $gray-300;

  p {
    font-family: 'NunitoSans-Regular', sans-serif;
    padding: 0;
    margin: 0;
    font-size: $font12;
    font-weight: $fWeight600;
    line-height: 16px;
    color: $black;
  }
}
