.input_container {
  position: relative;
}

.input_elem {
  &.with_left_icon {
    padding-left: 10%;
  }
}

.input_icon {
  position: absolute;
  top: 47%;
  left: 3%;
}
