* {
  font-family: $font_family_dashboard_main !important;
}

.galleryView {
  overflow: hidden;

  .row {
    margin: 0 -8px;
  }

  [class*='col-'] {
    padding: 0 8px;
    margin-bottom: 16px;
  }
}

.cardGraph {
  padding: 22px;
  background: #ffffff;
  border: 2px solid #efeeeb;
  border-radius: 12px;

  p {
    font-weight: 400;
    font-size: 12.2195px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #606060;
    margin: 0;
  }

  h4 {
    font-weight: 700;
    font-size: 22.4024px;
    line-height: 27px;
    letter-spacing: 0.01em;
    color: #000000;

    small {
      font-weight: 400;
      font-size: 14.2561px;
      line-height: 17px;
      letter-spacing: 0.01em;
      color: #292929;
    }
  }
}

.calendarDrop {
  min-width: 327px;
  background: #f5f5f5;
  border-radius: 16px;

  .calendarWrapper {
    .calendarHeader {
      padding: 24px 16px;

      .form-control {
        color: #fff;
        background: #101010;
        box-shadow: 2px 2px 8px rgba(16, 16, 16, 0.24);
        border-radius: 6px;
        font-weight: 400;
        font-size: 16px;

        &::placeholder {
          color: #fff;
        }
      }
    }

    .calendar-body {
      overflow: hidden;

      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          flex: 0 0 14.28%;
          max-width: 14.28%;
          margin: 4px 0;
          position: relative;
          text-align: center;

          &.selected {
            span {
              background: #07364b;
              color: #fff;
            }

            &.rangeStart {
              &::before {
                border-radius: 10px 0px 0px 10px;
                left: 14px;
              }
            }

            &.rangeEnd {
              &::before {
                border-radius: 0 10px 10px 0;
                right: 14px;
                left: auto;
              }
            }
          }

          &.range {
            &::before {
              content: '';
              background: rgba(7, 54, 75, 0.4);
              inset: 0;
              height: 100%;
              width: 100%;
              position: absolute;
            }
          }
        }

        span {
          width: 24px;
          height: 24px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          border-radius: 50%;
        }

        &.day {
          li {
            text-align: center;

            span {
              font-size: 16px;
              line-height: 20px;
              text-align: center;
              color: #808080;
              text-shadow: 2px 2px 8px rgba(16, 16, 16, 0.24);
            }
          }
        }
      }
    }

    .month {
      display: flex;
      align-items: center;
      padding: 8px 16px;

      .monthName {
        flex: 1;
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #101010;
        text-shadow: 2px 2px 8px rgba(16, 16, 16, 0.16);
      }

      i {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #e8e8e8;
        box-shadow: 2px 2px 8px rgba(16, 16, 16, 0.16);
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .cardGraph {
    margin-bottom: 16px;
  }
}

@media (max-width: 400px) {
  .footerCta .btn {
    min-width: 124px;
  }

  .calendarDrop {
    min-width: 313px;
  }
}
