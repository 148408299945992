// colors

$darkest-purple: #b00e35;
$dark-purple: #e71b4c;
$light-purple: #e71b4c;
$secondary_color: #a19276;
$black: #000000;
$light-black: #414141;
$black-opacity-4: #00000066;
$black-opacity-7: #000000B2;
$light-gray: #E7E7E7;
$load-primary: #BEAF87;
$primary-color: #BEAF87;
