
.loungeServiceInfo {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr;
  column-gap: 70px;

  @include midDesktop {
    grid-template-columns: 1fr 1fr;
  }

  @include minDesktop {
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
  }

  @include ipad {
    column-gap: 20px;
  }

  @include phone {
    grid-template-columns: 1fr;
  }
  .textSection {
    h3 {
      color: $black-light;
      font-size: 20px;
      @include Montserrat(600);

      @include ipad {
        font-size: 16px;
      }
      @include phone {
        font-size: 20px;
      }
    }
 
    p {
      color: $gray-700;
      font-size: 12px;
      @include Montserrat(400);

      @include ipad {
        font-size: 9px;
      }
      @include phone {
        font-size: 12px;
      }
    }
  }

  .videoSection {
    .videoContent {
      position: relative;
      width: fit-content;

      @include minDesktop {
        width: 100%;
      }

      .playButton {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }

      .video {
        width: 100%;
        border-radius: 15px;
      }
    }
  }
}
