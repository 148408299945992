*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
}

#portal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.6);
    align-items: center;
    justify-content: center;
}
