.download_progress_element_wrapper {
  .download_progress_element {
    display: flex;

    .download_progress_element_name {
      font-family: NunitoSans-Regular, "sans-serif";
      font-style: normal;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: #282828;
      margin: 5px 140px 5px 50px;
    }

    .download_progress_element_loading {
      margin: 5px 12px 5px auto;

      .download_progress_element_loading_done {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #42a040;
      }
    }
  }
}
