.image_item {
  height: 189px;
  width: 215px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  .img_part {
    position: relative;

    height: 143px;
    width: 100%;

    border-radius: $primary_border_radius;

    cursor: pointer;

    .cover_img {
      height: 100%;
      width: 100%;
      object-fit: cover;

      border-radius: $primary_border_radius;
    }

    .duration_item {
      position: absolute;
      right: 10px;
      bottom: 10px;

      height: 19px;
      width: 33px;
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 11px;
      font-weight: $fWeight500;

      background-color: $black_60;
      color: $white;
      border-radius: $tertiary_border_radius;
    }

    .slide_item {
      height: 143px !important;

      .image_slide {
        border-radius: $primary_border_radius;
      }
    }
  }

  .description_part {
    height: 100%;
    width: 100%;

    .description {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      font-size: $font14;
      font-weight: $fWeight500;
    }
  }

  .slick-slider {
    width: 100%;
    height: 100%;
  }
}
