* {
  font-family: $font_family_dashboard_main !important;
}

.paymentMessage {
  .card {
    padding: 130px 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .messageThanks {
      max-width: 582px;
      margin: 0 auto 60px;

      i {
        margin-bottom: 30px;
        display: inline-block;
      }

      h4 {
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        color: #07364b;
        margin-bottom: 7px;
      }

      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        text-align: center;
        color: #4f5b67;
      }
    }

    .message {
      max-width: 535px;
      margin: 0 auto;

      h4 {
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        color: #07364b;
      }

      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        text-align: center;
        color: #4f5b67;
      }

      ul {
        color: unset;

        li {
          list-style: circle;
        }
      }
    }

    .lounge_message {
      max-width: 705px;
    }

    .btnControl {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 12px;

      a {
        min-width: 210px;
        height: 52px;
        font-size: 14px;
        margin: 0 8px;
      }
    }
  }
}

@include media-breakpoint-down(xxl) {
  .paymentMessage .card {
    padding: 30px;
  }
}

@include media-breakpoint-down(sm) {
  .paymentMessage .card .btnControl {
    flex-direction: column;
  }

  .paymentMessage .card .btnControl a {
    margin-bottom: 10px;
  }

  .paymentMessage .card .messageThanks h4 {
    font-size: 18px;
  }

  .paymentMessage .card {
    padding: 16px;
  }

  .paymentMessage .card .messageThanks {
    margin-bottom: 30px;
  }

  .paymentMessage .card .messageThanks i {
    margin-bottom: 15px;
  }
  .paymentMessage .card .messageThanks i img {
    height: 60px;
  }
}
