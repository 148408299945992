.tooltip_container {
  position: absolute;
  top: -10px;
  left: calc(100% - 12px);

  border-radius: 8px;

  background: transparent;

  .react_tooltip {
    border: $primary_border;
    gap: 10px;
    display: flex;
    flex-direction: column;
    max-width: 500px;
    &::before {
      border-right: 8px solid $primary !important;
    }
  }

  * {
    color: $body-color;
    font-family: $font_family_dashboard_main;
  }
}

.subtitle {
  font-size: $font10;
  font-weight: $fWeight600;
}

.text {
  font-size: $font10;
  font-weight: $fWeight500;
}
