@import "../../../mixins.scss";

.photo_select_button_for_mobile_wrapper {

  .photo_select_button_for_mobile_select, .photo_select_button_for_mobile_cancel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 25px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 50px;
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    cursor: pointer;
  }

  .photo_select_button_for_mobile_cancel {

  }
}