.address_section_title {
  margin-bottom: 4px;
}

.address_details_container {
  margin-bottom: 15px;
}

.address_sec_container {
  label {
    margin-bottom: 4px;
  }
}

.address_sec_form_group {
  margin-bottom: 0;
}

.address_details_first_row {
  display: flex;
  width: 100%;
  gap: 16px;
}

.address_details_second_row {
  display: flex;
  width: 100%;
  gap: 8px;
  > .address_sec_container {
    flex: 1;
  }
}

.address_part_container {
  flex: 1;
  > div {
    height: 100%;
  }
}

.address_input_container {
  flex: 1;
}

.editingFieldsState.address.status {
  row-gap: 15px !important;
}

@media (max-width: 1065px) {
  .address_details_first_row {
    flex-wrap: wrap;
    > .address_sec_container {
      flex: unset;
    }
    * {
      width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .address_container {
    width: 100% !important;
  }
}

@media (max-width: 500px) {
  .address_details_second_row {
    flex-wrap: wrap;
    * {
      width: 100%;
    }
  }
  .address_sec_container {
    flex: unset !important;
  }
}
