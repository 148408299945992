//---------------------------------------------
//
//  Variables
//
//    1. Bootstrap Fonts
//    2. Bootstrap Globals
//    3. Bootstrap Colors
//    4. Bootstrap Container Width
//
//---------------------------------------------

//---------------------------------------------
// 1. Bootstrap Fonts
//---------------------------------------------

$font-family-base: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font_family_secondary: Nunito;
$font_family_dashboard_main: Montserrat;
$font_family_messages: Inter-Regular;

//---------------------------------------------
// 2. Bootstrap Globals
//---------------------------------------------

$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;

//---------------------------------------------
// 3. Bootstrap Colors
//---------------------------------------------

$body-color: #000000; // The default text color
$bodyBg: #fafafa;
$main_gray_bg: rgba(7, 54, 75, 0.05);
$main_gold_bg: #a192760d;
$light_blue_bg: #edf9ff;
$secondary: #6c757d;
$success: #44c13c;
$info: #0dcaf0;
$warning: #ffcf0f;
$warning_50: #fffaeb;
$warning_700: #b54708;
$darkWarning: #ab8e1a;
$danger: #e71b4c;
$light: #f1f1f1;
$gray: #d9d9d9;
$primary: #BEAF87;
$primary-bg: #beaf8733;
$primary-opacity: #a1927666;
$blue-dark: #07364b;
$primary_opacity_80: rgba(7, 54, 75, 0.8);
$primary_dark: #001d2b;
$dark: #212121;
$text-color: #101010;
$gray-900: #0c1116;
$gray-600: #4f5b67;
$gray-500: #808285;
$grey-100: #8c8c8c;
$gray-300: #9d9d9d;
$gray-800-8: #242d35cc;
$gray-800: #242d35;
$gray-700: #373f47;
$gray-50: #f9fafb;
$red: #eb3800;
$red-900: #e50000;
$gray100: #949494;
$white: #fff;
$gray200: #f7f7fb;
$border: #eaecee;
$section_disabled_bg_color: #f9f9f9;
$bg-gray: #f4f5f5;
$bg-gray-1: #f5f5f5;
$bg-gray-3: #f0f0f0;
$bg-gray-2: #f3f3f3;
$bg-gray-4: #FBFBFB;
$grey200: #a8b0b9;
$border7: #e7e7e7;
$border8: #efeeeb;
$website-gray: #c4c4c4;
$website-page-main-rose: #e71b4c;
$tooltips_main_bg_color: #f8f8f8;
$tours_step_main_bg_gray: #fafafa;
$pack_item_bg_color: #f3f5f5;
$black: #000000;
$black_60: rgba(0, 0, 0, 0.6);
$black_20: rgba(0, 0, 0, 0.2);
$black-light: #252526;
$black-200: #262626;
$dark-blue: #006edc;
$light-blue: #a2d0ff;
$black-600: #595959;
$light-primary: #BEAF8766;
$sale_black: #282828;
$sale_bg_color: #F3ECE0;
$package_sale_color: #B4A891;
$package_item_color: rgba(180, 168, 145, 0.40);
$sale_price_color: rgba(40, 40, 40, 0.70);
$sale_price_active: rgba(255, 255, 255, 0.70);

// Borders
$primary_border: 1px solid #a19276;
$select_btn_border: 2px solid $package_sale_color;
$package_sale_border: 1px solid  #B4A891;
$primary-bg-border: 1px solid rgba(190, 175, 135, 0.2);
$primary_thick_border: 2px solid #a19276;
$details_section_border: 1px solid #eaecee;
$border_black_opacity_30: 1px solid rgba(0, 0, 0, 0.3);
$main_gray_border: 1px solid #d0d5dd;
$border_gray_100: 1px solid #f2f4f7;
$border_gray_200: 1px solid #eaecee;

// Border Radius
$primary_border_radius_website: 5px;
$primary_border_radius: 8px;
$secondary_border_radius: 16px;
$tertiary_border_radius: 4px;
$border_radius_four: 12px;
$border_radius_five: 32px;

// Box Shadows
$box_shadow_lg: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
  0px 4px 6px -2px rgba(16, 24, 40, 0.03);
$box_shadow_base_bg_white: inset 0px 1px 0px #f1f1f1, inset 0px -1px 0px #f1f1f1;

// Font
$font8: 8px;
$font10: 10px;
$font12: 12px;
$font14: 14px;
$font16: 16px;
$font18: 18px;
$font24: 24px;
$font20: 20px;
$font28: 28px;

// Font Weight
$fWeight300: 300;
$fWeight400: 400;
$fWeight500: 500;
$fWeight600: 600;
$fWeight700: 700;
$fWeight1000: 1000;

// Opacities
$main_disabled_opacity: 0.8;
$main_disabled_container_bg_opacity: 0.2;

// Line Height
$line_height_18: 18px;

// Z-indexes
$z_index_first_floor: 0;
$z_index_second_floor: 9;
$z_index_third_floor: 99;
$z_index_fourth_floor: 999;
$z_index_fifth_floor: 9999;
$z_index_sixth_floor: 99999;
$z_index_seventh_floor: 999999;
$z_index_eighth_floor: 9999999;
$z_index_ninth_floor: 99999999;
$z_index_last_floor: 9999999999999;

//---------------------------------------------
// 4. Bootstrap Container Width
//---------------------------------------------

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);
