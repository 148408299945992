.horizontal_nav_container {
  display: flex;
  column-gap: 12px;
  overflow: auto;
  white-space: nowrap;
  padding: 17px 35px;
  background-color: $white;
  box-shadow: $box_shadow_base_bg_white;

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 38px;
    padding: 12px;

    font-size: $font14;
    font-weight: $fWeight500;

    background: $bg-gray-2;
    border-radius: $primary_border_radius;
    color: $primary;

    cursor: pointer;

    transition: 0.4s;

    &.active {
      background-color: $primary;
      color: $white;
    }
  }
}
