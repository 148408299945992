@import "../../mixins.scss";
    
    .quantity_text {
        font-family: "NunitoSans-Light";
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color:#414141;
        @include ipad {
            font-size: 10px;
            line-height: 14px;
        }
    }