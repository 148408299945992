@import '../../mixins.scss';

.download_button_popup_download_btn_wrapper {
  &:hover {
    .download_button_popup_dowload_section {
      display: block;
    }

    .download_button_popup_download_btn {
      color: $primary-color !important;
      background-color: #000000;
      border-radius: 5px;
      display: flex;
      justify-content: center;
    }

    .download_button_popup_download_btn::before {
      // content: url('../download-page-sections/images/arrowUp.svg');
      position: absolute;
      right: 10px;
      width: 10px;
      border-radius: 5px;
      @include ipad {
        width: 10px;
        right: 9px;
      }
      @include phone {
        right: 9px;
      }
    }
  }

  .download_button_popup_download_btn {
    width: 170px;
    height: 40px;
    font-size: 12px;
    line-height: 16px;
    outline: none;
    border: none;
    font-family: NunitoSans-Regular;
    transition: 0.3s linear;
    cursor: pointer;
    position: relative;
    background-color: $primary-color !important;
    color: #000000;
    padding-left: 0 !important;
    margin-left: 15px;
    display: flex;
    justify-content: flex-end;
    padding-right: 21px;
    align-items: center;
    border-radius: 5px;
    display: flex;
    justify-content: center;

    @include ipad {
      margin-top: 10px;
      height: 35px;
      width: 170px;
      margin-left: 10px;
      padding-right: 3.5rem;
      font-size: 12px;
      line-height: 16px;
    }

    .download_button_popup_liner_border {
      border-right: 1px solid $primary-color;
      width: 1px;
      height: 80%;
      margin: 0 -26px 0 30px;
      @include ipad {
        margin: 0 -54px 0 33px;
      }
      @include phone {
        margin: 0 -54px 0 33px;
      }
    }

    &::before {
      content: url('../../../../assets/icons/vector-down-light.svg');
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 10px;
      @include ipad {
        width: 10px;
        right: 9px;
      }
      @include phone {
        right: 10px;
      }
    }
  }
}

.download_button_popup_download_btn.download_button_popup_download_btn_disable {
  background-color: #cccccc;

  &:hover {
    background-color: #cccccc;
    color: #000000;
    cursor: not-allowed;
  }
}

.download_button_popup_download_options.download_button_popup_download_options_hide {
  display: none !important;
}

.download_button_popup_dowload_section {
  position: absolute;
  margin-left: 15px;
  width: 150px;
  display: flex;
  background: #ffffff;
  color: #000000;
  z-index: 99;
  display: none;
  border-radius: 0px 0px 5px 5px;

  @include ipad {
    width: 150px;
    font-size: 13px;
    margin-left: 10px;
  }
  @include ipad {
    width: 150px;
  }

  .download_button_popup_download_options {
    display: flex;
    flex-direction: column;

    span {
      font-family: NunitoSans-Regular;
      font-style: normal;
      font-size: 11px;
      line-height: 15px;
      display: flex;
      align-items: center;
      padding: 5px;
      cursor: pointer;

      &:hover {
        background: #ebebeb;
        border-radius: 0px 0px 5px 5px;
      }

      @include ipad {
        font-size: 11px;
        line-height: 15px;
      }
    }
  }
}

.download_button_popup_download_btn_opacity {
  opacity: 0.6;

  &:hover {
    background-color: $primary-color !important;
    color: #000000 !important;
    cursor: not-allowed;
  }
}

.notification_element_for_download_button {
  position: absolute;
  font-size: 12px;
  color: #000000;
  line-height: 16px;
  z-index: 1000;
}

.download_button_popup_download_btn {
&:hover {
    background-color: #000000 !important;
    background: #000000 !important;
    color: $primary-color !important;
  }
}
