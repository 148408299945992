@import '../../../../scss/variables';
@import '../../../../scss/abstracts.scss/_variables.scss';

.mobile_header_container {
  padding-top: 20px;
  position: fixed;
  width: 100vw;
  height: auto;
  top: 0;
  left: 0;
  z-index: $z_index_last_floor;
  background-color: #ffffff;
  transition: 0.5s;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 16px;
}

.nav_ul {
  list-style: none;
  width: 100%;
  height: auto;
}

.nav_item {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: $nav_menu_border;
  color: $secondary_color;
  font-size: 14px;
  font-weight: 600;
  font-family: Montserrat;
}

.mobile_header_container_closed {
  transform: translateY(-100%);
}

.navbar_close_icon {
  cursor: pointer;
  color: $secondary_color !important;
}
