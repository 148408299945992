 .nav-pills {
     border-bottom: 1px solid rgba($secondary_color, 0.5);
     max-width: 555px;
     width: 100%;
     margin: 0 auto;

     .nav-item {
         margin-right: 70px;

         &:last-child {
             margin: 0;
         }

         .nav-link {
            padding: 0 0 12px 0;
             font-size: $font16;
             color: rgba($secondary_color, 0.3);
             position: relative;
             @include Poppins(600);
             &::after{
                position: absolute;
                content: "";
                left: 0;
                bottom: -1px;
                right: 0;
                width: 0;
                height: 3px;
                background-color: $secondary_color;
             }
             &.active{
                background-color: transparent;
                color: rgba($secondary_color, 1);
                &::after{
                    width: 100%;
                }
             }
         }
     }
 }