* {
  font-family: $font_family_dashboard_main !important;
}

.cardProfile {
  padding: 24px;
  background: $white;
  border: 2px solid $border;
  border-radius: 12px;
  position: relative;

  .user {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .img-title {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-bottom: 9px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
      }
    }

    .name {
      font-weight: 600;
      font-size: $font14;
      line-height: 150%;
      text-align: center;
      color: $black-light;
      margin-bottom: 0;
    }

    .desg {
      font-weight: 400;
      font-size: $font12;
      line-height: 150%;
      text-align: center;
      color: $gray-600;
      margin-bottom: 0;
    }
  }

  .info {
    padding: 10px 0;

    span {
      display: block;
      font-weight: 400;
      font-size: $font12;
      line-height: 150%;
      color: $grey200;
      margin-bottom: 4px;
    }

    .detail {
      font-style: normal;
      font-weight: 400;
      font-size: $font14;
      line-height: 150%;
      color: $gray-700;

      p {
        font-weight: 500;
        cursor: pointer;
      }
    }
  }

  hr {
    margin: 0;
    border-top: 1px solid #a0a3a5;
  }

  .badges {
    position: absolute;
    top: -11px;
    right: 16px;

    i {
      margin-right: 5px;

      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}

.cardProperty {
  padding: 16px 24px;
  background: $white;
  border: 2px solid $border;
  border-radius: 12px;
  margin-bottom: 8px;

  .icon {
    width: 34px;
    height: 34px;
    background: $border;
    border-radius: 8px;
    margin-bottom: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  p {
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 12.2195px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #606060;

    strong {
      font-weight: 600;
    }
  }

  .counter {
    font-weight: 700;
    font-size: 22.4024px;
    line-height: 27px;
    letter-spacing: 0.01em;
    color: $primary;
  }
}

.editInfo {
  display: flex;
  padding: 16px 0;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.6);

  .media-body {
    flex: 1;

    h4 {
      font-weight: 500;
      font-size: $font14;
      line-height: 150%;
      color: $gray-800;
      margin: 0 0 8px;
    }

    p {
      margin: 0;
      font-weight: 500;
      font-size: $font12;
      line-height: 150%;
      color: $gray-800;
    }
  }

  a,
  u {
    font-weight: 600;
    font-size: $font14;
    line-height: 150%;
    text-decoration-line: underline;
    color: $gray-800;
    cursor: pointer;
  }
  .btn {
    max-width: 149px;
    width: 100%;
  }
}
