.price_input_container {
  display: flex;
  column-gap: 2px;

  .input_container {
    margin-bottom: 0;

    .form-control {
      height: unset;
      font-size: $font10;
    }

    label {
      display: none;
    }
  }
}

.not_specified_text {
  text-align: end;

  &.underlined {
    cursor: pointer;

    text-decoration: underline;
  }
}

.btn {
  &.small {
    font-size: $font10 !important;
    padding: 4px;
    height: fit-content;
    align-self: center;
  }
}
