.pure-modal {
  &.panel {
    padding: 20px 16px 16px;
  }
  .panel-body {
    padding: 16px !important;
  }
  .panel-footer {
    padding: 16px !important;
  }
  .close {
    display: none;
  }
}

.modal_footer_container {
  width: 100%;
  display: flex;
  gap: 12px;

  &.row {
    flex-direction: row;
  }
  &.column {
    flex-direction: column;
  }
  &.light {
    background-color: $white;
  }
}

.panel-heading {
  background-color: $white !important;
  border-radius: 12px 12px 0 0;
}

.panel-footer {
  background-color: $white !important;
  border-radius: 0 0 12px 12px;
}

.modal_header_container {
  &.header {
    background-color: $white;
  }

  .header_title {
    font-size: $font18;
    font-weight: $fWeight500;
    color: $primary;
  }
}

.content_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.content_text {
  color: $primary_opacity_80;
  font-size: $font14;
  font-weight: $fWeight400;
}
